import {
  AdRoasFilter,
  AdRoasHeaders,
  AggregationPeriod,
  ArpdauFilter,
  ArpdauGradientFlags,
  ArpdauHeaders,
  ArpdauSimpleFilter,
  ArpdauSimpleGradientFlags,
  ArpdauSimpleHeaders,
  ArpuFilter,
  BaseGradientFlags,
  CalendarPerMinFilter,
  CalendarPerMinGradientFlags,
  CalendarPerMinHeaders,
  CashCountryFilter,
  CashCountryGradientFlags,
  CashCountryHeaders,
  CashGamingFilter,
  CashGamingGradientFlags,
  CashGamingHeaders,
  CohortAnalysisFilter,
  CohortAnalysisHeaders,
  CohortCpmFilter,
  CohortGradientFlags,
  CohortHeaders,
  CohortPerMinFilter,
  CpmFilter,
  CpmGradientFlags,
  CpmHeaders,
  CtrCalendarFilter,
  CtrCalendarGradientFlags,
  CtrCalendarHeaders,
  CtrCohortFilter,
  DiffInstallsStoresFilter,
  DiffInstallsStoresGradientFlags,
  DiffInstallsStoresHeaders,
  EventsCostFilter,
  EventsCostGradientFlags,
  EventsCostHeaders,
  EventsSummaryFilter,
  EventsSummaryGradientFlags,
  EventsSummaryHeaders,
  EventsSummaryLiteFilter,
  EventsSummaryLiteGradientFlags,
  EventsSummaryLiteHeaders,
  FillRateFilter,
  FillRateGradientFlags,
  FillRateHeaders,
  FirebaseFillRateFilter,
  FirebaseFillRateHeaders,
  FirebaseShowToImpressionFilter,
  FirebaseShowToImpressionGradientFlags,
  FirebaseShowToImpressionHeaders,
  FirebaseVsNetworksFilter,
  FirebaseVsNetworksGradientFlags,
  FirebaseVsNetworksHeaders,
  GroupByType,
  MeasuresLiteFilter,
  MetricsConstructorFilter,
  MetricsConstructorGradientFlags,
  MetricsConstructorHeaders,
  MetricsFilter,
  MetricsHeaders,
  MetricsSpendFilter,
  MetricsSpendHeaders,
  MonetizationMonitoringFilter,
  MonetizationMonitoringHeaders,
  PayingUsersConversionFilter,
  PayingUsersConversionHeaders,
  PricedRevenueFilter,
  ProfitFilter,
  ProfitGradientFlags,
  ProfitHeaders,
  ReportDataType,
  ReportFilter,
  ReportHeader,
  ReturnRateFilter,
  RevenueFilter,
  RevenueGradientFlags,
  RevenueHeaders,
  SkadFilter,
  SkadHeaders,
  SpendFilter,
  SpendGradientFlags,
  SpendHeaders,
  SpendHyperHeaders,
  SpendMiniHeaders,
  SubscriptionIosFilter,
  SubscriptionIosHeaders,
  SubscriptionsOverviewFilter,
  SubscriptionsOverviewHeaders,
  TimeSpentFilter,
  TrafficQualityFilter,
  TrafficQualityHeaders,
  UaMainMetricsOverviewFilter,
  UaMainMetricsOverviewGradientFlags,
  UaMainMetricsOverviewHeaders,
  UsersActivityType,
  WaterfallFilter,
  WaterfallHeaders,
} from "@/reports/models";
import {
  ReportGroupModel,
  ReportItemModel,
  ReportModel,
} from "@/reports/models/ReportMenu";
import {
  GroupReportNames,
  REPORT_SORT_ORDER,
  ReportChartConditionMessage,
  ReportType,
} from "@/reports/models/ReportType";
import {
  SEGMENT_ACTIVE_STATUSES,
  SEGMENT_TYPES_FOR_REPORTS,
} from "@/segments/models/SegmentModel";
import {
  Application,
  Dictionary,
  FilterColor,
  FilterId,
  FilterModel,
  FilterPreviewId,
  REPORT_FILTERS,
  REPORT_GROUP_BY_FILTER_ID,
  SegmentDictionary,
  TrackerDictionary,
  TrackerOrigin,
  getFilterIdToInitFunction,
} from "@/shared/models";
import {
  AggregationPeriodFilterPreviewModel,
  DateFilterPreviewModel,
  DayLimitFilterPreviewModel,
  FilterPreviewModel,
  GroupByFilterPreviewModel,
} from "@/shared/models/FilterPreviewModel";
import { LangService } from "@/shared/types/LangType";

export default class ReportUtil {
  private static readonly REPORT_NAMES: Record<ReportType, string> = {
    REVENUE: "Revenue",
    ARPDAU: "ARPDAU",
    ARPDAU_SIMPLE: "ARPDAU Simple",
    ARPU: "ARPU",
    RETURN_RATE: "Return Rate",
    EVENTS_SUMMARY: "Events Summary",
    MEASURES_LITE: "Measures Lite",
    TIME_SPENT: "Time Spent",
    COHORT_PER_MIN: "Cohort Permin",
    CALENDAR_PER_MIN: "Calendar Permin",
    SPEND_HYPER: "Spend Hyper",
    SPEND_MINI: "Spend Mini",
    SPEND: "Spend",
    COHORT_ANALYSIS: "Cohort Analysis",
    PROFIT: "Profit",
    CALENDAR_CPM: "Calendar CPM",
    METRICS: "Metrics",
    WATERFALL: "Waterfall",
    EVENTS_COST: "Events Cost",
    CALENDAR_CTR: "Calendar CTR",
    CASH_COUNTRY: "Cash Country",
    DIFF_INSTALLS_STORES: "Diff Installs Stores",
    COHORT_CTR: "Cohort CTR",
    AD_ROAS_COUNTRY: "Ad ROAS Country",
    AD_ROAS_NETWORK: "Ad ROAS Network",
    CASH_GAMING: "Cash Gaming",
    COHORT_CPM: "Cohort CPM",
    SKAD: "SKAd",
    METRICS_SPEND: "Metrics and Spend",
    FILL_RATE: "Fill Rate",
    PAYING_USERS_CONVERSION: "Paying Users Conversion",
    PRICED_REVENUE: "Priced Revenue",
    MONETIZATION_MONITORING: "Monetization monitoring",
    FIREBASE_SHOW_TO_IMPRESSION: "Show to Impression",
    FIREBASE_VS_NETWORKS: "Firebase vs Networks",
    FIREBASE_FILL_RATE: "Firebase Fill Rate",
    TRAFFIC_QUALITY: "Traffic Quality",
    UA_MAIN_METRICS_OVERVIEW: "UA Main",
    SUBSCRIPTIONS_OVERVIEW: "GP Subscriptions Overview",
    METRICS_CONSTRUCTOR: "Metrics Constructor",
    SUBSCRIPTIONS_OVERVIEW_IOS: "iOS Subscriptions Overview",
    EVENTS_SUMMARY_LITE: "Events Summary Lite",
  };

  private static readonly REPORT_ICONS = new Map<string, string>([
    [ReportType.ARPDAU, "mdi-signal"],
    [ReportType.ARPDAU_SIMPLE, "signal_cellular_alt"],
    [ReportType.ARPU, "functions"],
    [ReportType.REVENUE, "mdi-currency-usd"],
    [ReportType.EVENTS_SUMMARY, "event"],
    [ReportType.MEASURES_LITE, "trending_up"],
    [ReportType.RETURN_RATE, "mdi-reiterate"],
    [ReportType.TIME_SPENT, "mdi-sort-clock-descending"],
    [ReportType.METRICS, "mdi-axis-arrow"],
    [ReportType.COHORT_PER_MIN, "mdi-account-clock"],
    [ReportType.CALENDAR_PER_MIN, "mdi-calendar-clock"],
    [GroupReportNames.EVENTS_SUMMARY, "event"],
    [GroupReportNames.REVENUE, "mdi-currency-usd"],
    [GroupReportNames.COHORT, "sort"],
    [GroupReportNames.PER_MIN, "mdi-clock-fast"],
    [GroupReportNames.SPEND, "cases"],
    [GroupReportNames.CTR, "mdi-cursor-default-click"],
    [GroupReportNames.CASH, "mdi-cash-register"],
    [GroupReportNames.CPM, "mdi-account-cash"],
    [GroupReportNames.FIREBASE, "mdi-fire"],
    [ReportType.SPEND_HYPER, "next_week"],
    [ReportType.SPEND_MINI, "work_outline"],
    [ReportType.SPEND, "business_center"],
    [ReportType.COHORT_ANALYSIS, "mdi-sigma"],
    [ReportType.PROFIT, "mdi-cash-multiple"],
    [ReportType.CALENDAR_CPM, "mdi-account-cash"],
    [ReportType.WATERFALL, "mdi-waterfall"],
    [ReportType.EVENTS_COST, "mdi-hand-coin"],
    [ReportType.CALENDAR_CTR, "mdi-calendar-cursor"],
    [ReportType.CASH_COUNTRY, "mdi-earth"],
    [ReportType.DIFF_INSTALLS_STORES, "mdi-store-cog"],
    [ReportType.COHORT_CTR, "mdi-cursor-default-click-outline"],
    [ReportType.AD_ROAS_COUNTRY, "mdi-wan"],
    [ReportType.AD_ROAS_NETWORK, "mdi-plus-network-outline"],
    [ReportType.CASH_GAMING, "mdi-controller"],
    [ReportType.COHORT_CPM, "mdi-account-cash-outline"],
    [ReportType.SKAD, "mdi-apple"],
    [ReportType.METRICS_SPEND, "mdi-abacus"],
    [ReportType.FILL_RATE, "mdi-format-color-fill"],
    [ReportType.PAYING_USERS_CONVERSION, "mdi-cash-sync"],
    [ReportType.PRICED_REVENUE, "mdi-currency-sign"],
    [ReportType.FIREBASE_SHOW_TO_IMPRESSION, "mdi-eye"],
    [ReportType.FIREBASE_VS_NETWORKS, "mdi-compare"],
    [ReportType.FIREBASE_FILL_RATE, "mdi-menorah-fire"],
    [ReportType.TRAFFIC_QUALITY, "mdi-transfer"],
    [ReportType.UA_MAIN_METRICS_OVERVIEW, "mdi-finance"],
    [ReportType.SUBSCRIPTIONS_OVERVIEW, "mdi-youtube-subscription"],
    [ReportType.METRICS_CONSTRUCTOR, "mdi-toy-brick-plus-outline"],
    [ReportType.SUBSCRIPTIONS_OVERVIEW_IOS, "mdi-autorenew"],
    [ReportType.EVENTS_SUMMARY_LITE, "mdi-calendar-range"],
  ]);

  static getReportNameByReportType(reportType: ReportType) {
    return this.REPORT_NAMES[reportType];
  }

  static getFilterModel(
    reportFilter: ReportFilter,
    filterId: FilterId,
    defaultValues: Array<string> = []
  ): FilterModel {
    return (
      reportFilter.filter.find((it) => it.id === filterId) ||
      getFilterIdToInitFunction(filterId, defaultValues)
    );
  }

  static setFilterModel(
    reportFilter: ReportFilter,
    filterModel: FilterModel
  ): void {
    const index = reportFilter.filter.findIndex(
      (item) => item.id === filterModel.id
    );

    if (index === -1 && !filterModel.isEmpty()) {
      reportFilter.filter.push(filterModel);
      return;
    }

    if (index !== -1 && filterModel.isEmpty()) {
      reportFilter.filter.splice(index, 1);
    }
  }

  static getCopyOfReportFilter(
    filter: Record<string, any>,
    reportType: ReportType,
    apps: Array<Application>
  ) {
    return {
      REVENUE: () => RevenueFilter.of(apps, filter),
      ARPDAU: () => ArpdauFilter.of(apps[0], filter),
      ARPDAU_SIMPLE: () => ArpdauSimpleFilter.of(apps[0], filter),
      ARPU: () => ArpuFilter.of(apps[0], filter),
      RETURN_RATE: () => ReturnRateFilter.of(apps[0], filter),
      EVENTS_SUMMARY: () => EventsSummaryFilter.of(apps[0], filter),
      MEASURES_LITE: () => MeasuresLiteFilter.of(apps[0], filter),
      TIME_SPENT: () => TimeSpentFilter.of(apps[0], filter),
      COHORT_PER_MIN: () => CohortPerMinFilter.of(apps[0], filter),
      CALENDAR_PER_MIN: () => CalendarPerMinFilter.of(apps[0], filter),
      SPEND: () => SpendFilter.of(ReportType.SPEND, apps, filter),
      SPEND_MINI: () => SpendFilter.of(ReportType.SPEND_MINI, apps, filter),
      SPEND_HYPER: () => SpendFilter.of(ReportType.SPEND_HYPER, apps, filter),
      COHORT_ANALYSIS: () => CohortAnalysisFilter.of(apps[0], filter),
      PROFIT: () => ProfitFilter.of(apps, filter),
      CALENDAR_CPM: () => CpmFilter.of(apps, filter),
      METRICS: () => MetricsFilter.of(apps[0], filter),
      WATERFALL: () => WaterfallFilter.of(apps, filter),
      EVENTS_COST: () => EventsCostFilter.of(apps[0], filter),
      CALENDAR_CTR: () => CtrCalendarFilter.of(apps[0], filter),
      CASH_COUNTRY: () => CashCountryFilter.of(apps[0], filter),
      DIFF_INSTALLS_STORES: () => DiffInstallsStoresFilter.of(apps[0], filter),
      COHORT_CTR: () => CtrCohortFilter.of(apps[0], filter),
      AD_ROAS_COUNTRY: () => AdRoasFilter.of(reportType, apps[0], filter),
      AD_ROAS_NETWORK: () => AdRoasFilter.of(reportType, apps[0], filter),
      CASH_GAMING: () => CashGamingFilter.of(apps[0], filter),
      COHORT_CPM: () => CohortCpmFilter.of(apps[0], filter),
      SKAD: () => SkadFilter.of(apps[0], filter),
      METRICS_SPEND: () => MetricsSpendFilter.of(apps[0], filter),
      FILL_RATE: () => FillRateFilter.of(apps[0], filter),
      PAYING_USERS_CONVERSION: () =>
        PayingUsersConversionFilter.of(apps[0], filter),
      PRICED_REVENUE: () => PricedRevenueFilter.of(apps[0], filter),
      MONETIZATION_MONITORING: () =>
        MonetizationMonitoringFilter.of(apps[0], filter),
      FIREBASE_SHOW_TO_IMPRESSION: () =>
        FirebaseShowToImpressionFilter.of(apps[0], filter),
      FIREBASE_VS_NETWORKS: () => FirebaseVsNetworksFilter.of(apps[0], filter),
      FIREBASE_FILL_RATE: () => FirebaseFillRateFilter.of(apps[0], filter),
      TRAFFIC_QUALITY: () => TrafficQualityFilter.of(apps[0], filter),
      UA_MAIN_METRICS_OVERVIEW: () =>
        UaMainMetricsOverviewFilter.of(apps[0], filter),
      SUBSCRIPTIONS_OVERVIEW: () =>
        SubscriptionsOverviewFilter.of(apps[0], filter),
      METRICS_CONSTRUCTOR: () => MetricsConstructorFilter.of(apps[0], filter),
      SUBSCRIPTIONS_OVERVIEW_IOS: () =>
        SubscriptionIosFilter.of(apps[0], filter),
      EVENTS_SUMMARY_LITE: () => EventsSummaryLiteFilter.of(apps[0], filter),
    }[reportType]();
  }

  private static getReportName(reportType: ReportType): string {
    return (
      this.getReportNameByReportType(reportType) || reportType.toUpperCase()
    );
  }

  private static getReportGroupName(
    reportType: ReportType
  ): GroupReportNames | null {
    switch (reportType) {
      case ReportType.EVENTS_SUMMARY:
      case ReportType.EVENTS_SUMMARY_LITE:
        return GroupReportNames.EVENTS_SUMMARY;
      case ReportType.ARPU:
      case ReportType.RETURN_RATE:
      case ReportType.MEASURES_LITE:
      case ReportType.TIME_SPENT:
      case ReportType.METRICS:
      case ReportType.AD_ROAS_COUNTRY:
      case ReportType.AD_ROAS_NETWORK:
      case ReportType.EVENTS_COST:
      case ReportType.SKAD:
      case ReportType.METRICS_SPEND:
      case ReportType.PAYING_USERS_CONVERSION:
      case ReportType.TRAFFIC_QUALITY:
      case ReportType.UA_MAIN_METRICS_OVERVIEW:
      case ReportType.SUBSCRIPTIONS_OVERVIEW:
      case ReportType.METRICS_CONSTRUCTOR:
      case ReportType.SUBSCRIPTIONS_OVERVIEW_IOS:
        return GroupReportNames.COHORT;
      case ReportType.COHORT_PER_MIN:
      case ReportType.CALENDAR_PER_MIN:
        return GroupReportNames.PER_MIN;
      case ReportType.SPEND:
      case ReportType.SPEND_MINI:
      case ReportType.SPEND_HYPER:
        return GroupReportNames.SPEND;
      case ReportType.ARPDAU:
      case ReportType.ARPDAU_SIMPLE:
        return GroupReportNames.ARPDAU;
      case ReportType.CALENDAR_CTR:
      case ReportType.COHORT_CTR:
        return GroupReportNames.CTR;
      case ReportType.CASH_COUNTRY:
      case ReportType.CASH_GAMING:
        return GroupReportNames.CASH;
      case ReportType.CALENDAR_CPM:
      case ReportType.COHORT_CPM:
        return GroupReportNames.CPM;
      case ReportType.REVENUE:
      case ReportType.PRICED_REVENUE:
        return GroupReportNames.REVENUE;
      case ReportType.FIREBASE_VS_NETWORKS:
      case ReportType.FIREBASE_SHOW_TO_IMPRESSION:
      case ReportType.FIREBASE_FILL_RATE:
        return GroupReportNames.FIREBASE;
      default:
        return null;
    }
  }

  // TODO: temp method
  static isMultiAppReport(id: ReportType) {
    return [
      ReportType.REVENUE,
      ReportType.SPEND,
      ReportType.SPEND_HYPER,
      ReportType.SPEND_MINI,
      ReportType.CALENDAR_CPM,
      ReportType.PROFIT,
      ReportType.WATERFALL,
    ].includes(id);
  }

  static createReportsMenu(reports: Array<ReportType>) {
    const menuItems: Array<ReportModel> = [];
    const groupItems: Map<string, ReportGroupModel> = new Map<
      string,
      ReportGroupModel
    >();

    REPORT_SORT_ORDER.forEach((reportType) => {
      if (!reports.includes(reportType)) {
        return;
      }

      const reportName = this.getReportName(reportType);
      const reportGroupName = this.getReportGroupName(reportType);

      if (reportGroupName) {
        let reportGroup = groupItems.get(reportGroupName);

        if (!reportGroup) {
          reportGroup = new ReportGroupModel(reportGroupName);

          groupItems.set(reportGroupName, reportGroup);
          menuItems.push(reportGroup);
        }

        reportGroup.items.push(
          new ReportItemModel(
            reportType,
            reportName,
            this.isMultiAppReport(reportType)
          )
        );
      } else {
        menuItems.push(
          new ReportItemModel(
            reportType,
            reportName,
            this.isMultiAppReport(reportType)
          )
        );
      }
    });

    return menuItems;
  }

  static getReportChartConditions(
    type: ReportType,
    lang: LangService
  ): Array<string> {
    return ((reportType: ReportType): Array<ReportChartConditionMessage> => {
      switch (reportType) {
        case ReportType.EVENTS_SUMMARY:
        case ReportType.EVENTS_SUMMARY_LITE:
          return [
            ReportChartConditionMessage.GROUP_BY_LESS_THAN_TWO_FILTERS,
            ReportChartConditionMessage.EVENT_FILTER_AND_AGG_PERIOD,
          ];
        case ReportType.REVENUE:
        case ReportType.PRICED_REVENUE:
          return [ReportChartConditionMessage.GROUP_BY_LESS_THAN_TWO_FILTERS];
        case ReportType.WATERFALL:
          return [
            ReportChartConditionMessage.AGGREGATION_PERIOD,
            ReportChartConditionMessage.GROUP_BY,
          ];
        default:
          return [];
      }
    })(type).map((message: string) =>
      lang(`chartCondition.${message.toLowerCase()}`)
    );
  }

  static getIcon(val: string) {
    return this.REPORT_ICONS.get(val) || "";
  }

  static getReportFilterByReportType(
    reportType: ReportType,
    apps: Array<Application>,
    filter: any
  ) {
    let processedFilter: Record<string, any>;

    if (filter) {
      processedFilter = {
        ...filter,
        ...(filter.filter?.length
          ? {
              filter: filter.filter.filter(({ id }: { id: FilterId }) =>
                REPORT_FILTERS[reportType].includes(id)
              ),
            }
          : {}),
      };
    }

    return {
      MEASURES_LITE: () => MeasuresLiteFilter.of(apps[0], processedFilter),
      ARPU: () => ArpuFilter.of(apps[0], processedFilter),
      TIME_SPENT: () => TimeSpentFilter.of(apps[0], processedFilter),
      RETURN_RATE: () => ReturnRateFilter.of(apps[0], processedFilter),
      COHORT_PER_MIN: () => CohortPerMinFilter.of(apps[0], processedFilter),
      CALENDAR_PER_MIN: () => CalendarPerMinFilter.of(apps[0], processedFilter),
      ARPDAU: () => ArpdauFilter.of(apps[0], processedFilter),
      ARPDAU_SIMPLE: () => ArpdauSimpleFilter.of(apps[0], processedFilter),
      REVENUE: () => RevenueFilter.of(apps, processedFilter),
      EVENTS_SUMMARY: () => EventsSummaryFilter.of(apps[0], processedFilter),
      SPEND: () => SpendFilter.of(reportType, apps, processedFilter),
      SPEND_HYPER: () => SpendFilter.of(reportType, apps, processedFilter),
      SPEND_MINI: () => SpendFilter.of(reportType, apps, processedFilter),
      COHORT_ANALYSIS: () => CohortAnalysisFilter.of(apps[0], processedFilter),
      PROFIT: () => ProfitFilter.of(apps, processedFilter),
      CALENDAR_CPM: () => CpmFilter.of(apps, processedFilter),
      METRICS: () => MetricsFilter.of(apps[0], processedFilter),
      WATERFALL: () => WaterfallFilter.of(apps, processedFilter),
      EVENTS_COST: () => EventsCostFilter.of(apps[0], processedFilter),
      CALENDAR_CTR: () => CtrCalendarFilter.of(apps[0], processedFilter),
      CASH_COUNTRY: () => CashCountryFilter.of(apps[0], processedFilter),
      DIFF_INSTALLS_STORES: () =>
        DiffInstallsStoresFilter.of(apps[0], processedFilter),
      COHORT_CTR: () => CtrCohortFilter.of(apps[0], processedFilter),
      AD_ROAS_COUNTRY: () =>
        AdRoasFilter.of(reportType, apps[0], processedFilter),
      AD_ROAS_NETWORK: () =>
        AdRoasFilter.of(reportType, apps[0], processedFilter),
      CASH_GAMING: () => CashGamingFilter.of(apps[0], processedFilter),
      COHORT_CPM: () => CohortCpmFilter.of(apps[0], processedFilter),
      SKAD: () => SkadFilter.of(apps[0], processedFilter),
      METRICS_SPEND: () => MetricsSpendFilter.of(apps[0], processedFilter),
      FILL_RATE: () => FillRateFilter.of(apps[0], processedFilter),
      PAYING_USERS_CONVERSION: () =>
        PayingUsersConversionFilter.of(apps[0], processedFilter),
      PRICED_REVENUE: () => PricedRevenueFilter.of(apps[0], processedFilter),
      MONETIZATION_MONITORING: () =>
        MonetizationMonitoringFilter.of(apps[0], processedFilter),
      FIREBASE_SHOW_TO_IMPRESSION: () =>
        FirebaseShowToImpressionFilter.of(apps[0], processedFilter),
      FIREBASE_VS_NETWORKS: () =>
        FirebaseVsNetworksFilter.of(apps[0], processedFilter),
      FIREBASE_FILL_RATE: () =>
        FirebaseFillRateFilter.of(apps[0], processedFilter),
      TRAFFIC_QUALITY: () => TrafficQualityFilter.of(apps[0], processedFilter),
      UA_MAIN_METRICS_OVERVIEW: () =>
        UaMainMetricsOverviewFilter.of(apps[0], processedFilter),
      SUBSCRIPTIONS_OVERVIEW: () =>
        SubscriptionsOverviewFilter.of(apps[0], processedFilter),
      METRICS_CONSTRUCTOR: () =>
        MetricsConstructorFilter.of(apps[0], processedFilter),
      SUBSCRIPTIONS_OVERVIEW_IOS: () =>
        SubscriptionIosFilter.of(apps[0], processedFilter),
      EVENTS_SUMMARY_LITE: () =>
        EventsSummaryLiteFilter.of(apps[0], processedFilter),
    }[reportType]();
  }

  static initHeaders(
    lang: LangService,
    report: any,
    reportDataType: ReportDataType,
    events?: Array<{ name: string; value: string }>,
    groupBy?: GroupByType
  ): Array<ReportHeader> {
    return {
      AD_ROAS_COUNTRY: () => AdRoasHeaders.init(lang, report, reportDataType),
      AD_ROAS_NETWORK: () => AdRoasHeaders.init(lang, report, reportDataType),
      ARPDAU: () => ArpdauHeaders.init(lang, report),
      ARPDAU_SIMPLE: () => ArpdauSimpleHeaders.init(lang, report),
      ARPU: () =>
        CohortHeaders.init(
          lang,
          report,
          reportDataType,
          CohortHeaders.PRECISION_FOR_ARPU
        ),
      CALENDAR_CPM: () => CpmHeaders.init(lang, report),
      CALENDAR_CTR: () => CtrCalendarHeaders.init(lang, report, reportDataType),
      CALENDAR_PER_MIN: () =>
        CalendarPerMinHeaders.init(lang, report, reportDataType),
      CASH_COUNTRY: () => CashCountryHeaders.init(lang, report, reportDataType),
      CASH_GAMING: () => CashGamingHeaders.init(lang, report, reportDataType),
      COHORT_ANALYSIS: () =>
        CohortAnalysisHeaders.init(lang, report, reportDataType),
      COHORT_CPM: () => CohortHeaders.init(lang, report, reportDataType),
      COHORT_CTR: () => CohortHeaders.init(lang, report, reportDataType),
      COHORT_PER_MIN: () => CohortHeaders.init(lang, report, reportDataType),
      DIFF_INSTALLS_STORES: () =>
        DiffInstallsStoresHeaders.init(lang, reportDataType),
      EVENTS_COST: () =>
        EventsCostHeaders.init(lang, report, reportDataType, events),
      EVENTS_SUMMARY: () => EventsSummaryHeaders.init(lang, report),
      FILL_RATE: () => FillRateHeaders.init(lang, report),
      MEASURES_LITE: () => CohortHeaders.init(lang, report, reportDataType),
      METRICS: () =>
        MetricsHeaders.init(
          lang,
          report,
          reportDataType,
          groupBy as GroupByType
        ),
      METRICS_SPEND: () =>
        MetricsSpendHeaders.init(
          lang,
          report,
          reportDataType,
          groupBy as GroupByType
        ),
      PAYING_USERS_CONVERSION: () =>
        PayingUsersConversionHeaders.init(
          lang,
          report,
          reportDataType,
          groupBy as GroupByType
        ),
      PRICED_REVENUE: () => RevenueHeaders.init(lang, report),
      PROFIT: () => ProfitHeaders.init(lang, report),
      RETURN_RATE: () => CohortHeaders.init(lang, report, reportDataType),
      REVENUE: () => RevenueHeaders.init(lang, report),
      SKAD: () =>
        SkadHeaders.init(lang, report, reportDataType, groupBy as GroupByType),
      SPEND: () => SpendHeaders.init(lang, report, reportDataType),
      SPEND_HYPER: () => SpendHyperHeaders.init(lang, report, reportDataType),
      SPEND_MINI: () => SpendMiniHeaders.init(lang, report),
      TIME_SPENT: () => CohortHeaders.init(lang, report, reportDataType),
      WATERFALL: () => WaterfallHeaders.init(lang, report),
      MONETIZATION_MONITORING: () =>
        MonetizationMonitoringHeaders.init(lang, reportDataType),
      FIREBASE_SHOW_TO_IMPRESSION: () =>
        FirebaseShowToImpressionHeaders.init(
          lang,
          report,
          groupBy as GroupByType
        ),
      FIREBASE_VS_NETWORKS: () =>
        FirebaseVsNetworksHeaders.init(lang, report, groupBy as GroupByType),
      FIREBASE_FILL_RATE: () =>
        FirebaseFillRateHeaders.init(lang, report, groupBy as GroupByType),
      TRAFFIC_QUALITY: () =>
        TrafficQualityHeaders.init(
          lang,
          report,
          reportDataType,
          groupBy as GroupByType
        ),
      UA_MAIN_METRICS_OVERVIEW: () =>
        UaMainMetricsOverviewHeaders.init(
          lang,
          report,
          reportDataType,
          groupBy as GroupByType
        ),
      SUBSCRIPTIONS_OVERVIEW: () =>
        SubscriptionsOverviewHeaders.init(lang, report, reportDataType),
      METRICS_CONSTRUCTOR: () =>
        MetricsConstructorHeaders.init(
          lang,
          report,
          reportDataType,
          groupBy as GroupByType
        ),
      SUBSCRIPTIONS_OVERVIEW_IOS: () =>
        SubscriptionIosHeaders.init(lang, report, reportDataType),
      EVENTS_SUMMARY_LITE: () => EventsSummaryLiteHeaders.init(lang, report),
    }[report.reportId as ReportType]();
  }

  static initGradientFlags(
    report: ReportFilter,
    reportDataType: ReportDataType
  ): BaseGradientFlags | null {
    if (reportDataType === ReportDataType.TOTAL) {
      return null;
    }

    return {
      AD_ROAS_COUNTRY: () => new CohortGradientFlags(),
      AD_ROAS_NETWORK: () => new CohortGradientFlags(),
      ARPDAU: () => new ArpdauGradientFlags(),
      ARPDAU_SIMPLE: () =>
        new ArpdauSimpleGradientFlags(
          (report as ArpdauSimpleFilter).usersActivityType ===
            UsersActivityType.ALL
        ),
      ARPU: () => new CohortGradientFlags(),
      CALENDAR_CPM: () => new CpmGradientFlags(),
      CALENDAR_CTR: () => new CtrCalendarGradientFlags(),
      CALENDAR_PER_MIN: () => new CalendarPerMinGradientFlags(),
      CASH_COUNTRY: () => new CashCountryGradientFlags(),
      CASH_GAMING: () => new CashGamingGradientFlags(),
      COHORT_ANALYSIS: () =>
        reportDataType === ReportDataType.SUB_TOTAL
          ? null
          : new CohortGradientFlags(),
      COHORT_CPM: () => new CohortGradientFlags(),
      COHORT_CTR: () => new CohortGradientFlags(),
      COHORT_PER_MIN: () => new CohortGradientFlags(),
      DIFF_INSTALLS_STORES: () => new DiffInstallsStoresGradientFlags(),
      EVENTS_COST: () => new EventsCostGradientFlags(),
      EVENTS_SUMMARY: () => new EventsSummaryGradientFlags(),
      FILL_RATE: () => new FillRateGradientFlags(),
      MEASURES_LITE: () => new EventsCostGradientFlags(),
      METRICS: () => new CohortGradientFlags(),
      METRICS_SPEND: () => new CohortGradientFlags(),
      PAYING_USERS_CONVERSION: () => new CohortGradientFlags(),
      PRICED_REVENUE: () => new RevenueGradientFlags(report),
      PROFIT: () => new ProfitGradientFlags(report),
      RETURN_RATE: () => new CohortGradientFlags(),
      REVENUE: () => new RevenueGradientFlags(report),
      SKAD: () => new CohortGradientFlags(),
      SPEND: () => new SpendGradientFlags(report),
      SPEND_HYPER: () => new SpendGradientFlags(report),
      SPEND_MINI: () => null,
      TIME_SPENT: () => new CohortGradientFlags(),
      WATERFALL: () => null,
      MONETIZATION_MONITORING: () => null,
      FIREBASE_SHOW_TO_IMPRESSION: () =>
        new FirebaseShowToImpressionGradientFlags(),
      FIREBASE_VS_NETWORKS: () => new FirebaseVsNetworksGradientFlags(),
      FIREBASE_FILL_RATE: () => new CohortGradientFlags(),
      TRAFFIC_QUALITY: () => new CohortGradientFlags(),
      UA_MAIN_METRICS_OVERVIEW: () => new UaMainMetricsOverviewGradientFlags(),
      SUBSCRIPTIONS_OVERVIEW: () => null,
      METRICS_CONSTRUCTOR: () => new MetricsConstructorGradientFlags(),
      SUBSCRIPTIONS_OVERVIEW_IOS: () => null,
      EVENTS_SUMMARY_LITE: () =>
        new EventsSummaryLiteGradientFlags(
          (report as EventsSummaryLiteFilter).showUniqueDevices
        ),
    }[report.reportId as ReportType]();
  }

  static getAvailableReportFilters(
    reportType: ReportType
  ): Array<FilterPreviewModel> {
    switch (reportType) {
      case ReportType.ARPU:
        return [
          new DateFilterPreviewModel(),
          new FilterPreviewModel(FilterPreviewId.SEGMENT, FilterColor.RED),
          new FilterPreviewModel(
            FilterPreviewId.COUNTRY,
            FilterColor.LIGHT_BLUE
          ),
          new FilterPreviewModel(
            FilterPreviewId.TRACKER,
            FilterColor.LIGHT_GREEN
          ),
          new FilterPreviewModel(FilterPreviewId.ATTRIBUTION_STATUS),
          new FilterPreviewModel(FilterPreviewId.DEVICE_TYPE),
          new FilterPreviewModel(FilterPreviewId.LAT),
          new FilterPreviewModel(FilterPreviewId.AD_NETWORK_NAME),
          new FilterPreviewModel(FilterPreviewId.REVENUE),
          new FilterPreviewModel(FilterPreviewId.AD_REVENUE_METHOD),
          new FilterPreviewModel(FilterPreviewId.PROVIDED_BANNER),
          new FilterPreviewModel(FilterPreviewId.EVENT_SIMPLE),
          new GroupByFilterPreviewModel(
            [...REPORT_GROUP_BY_FILTER_ID, FilterId.AD_NETWORK_NAME],
            undefined,
            true
          ),
          new FilterPreviewModel(FilterPreviewId.CUMULATIVE),
        ];
      case ReportType.AD_ROAS_COUNTRY:
        return [
          new DateFilterPreviewModel(),
          new FilterPreviewModel(
            FilterPreviewId.COUNTRY,
            FilterColor.LIGHT_BLUE
          ),
          new FilterPreviewModel(FilterPreviewId.REVENUE),
          new FilterPreviewModel(FilterPreviewId.AD_REVENUE_METHOD),
          new FilterPreviewModel(FilterPreviewId.PROVIDED_BANNER),
          new GroupByFilterPreviewModel([FilterId.COUNTRY]),
        ];
      case ReportType.AD_ROAS_NETWORK:
        return [
          new DateFilterPreviewModel(),
          new FilterPreviewModel(
            FilterPreviewId.COUNTRY,
            FilterColor.LIGHT_BLUE
          ),
          new FilterPreviewModel(
            FilterPreviewId.TRACKER,
            FilterColor.LIGHT_GREEN
          ),
          new FilterPreviewModel(FilterPreviewId.REVENUE),
          new FilterPreviewModel(FilterPreviewId.AD_REVENUE_METHOD),
          new FilterPreviewModel(FilterPreviewId.PROVIDED_BANNER),
          new GroupByFilterPreviewModel(
            [
              FilterId.COUNTRY,
              FilterId.SOURCE,
              FilterId.CAMPAIGN,
              FilterId.AD_SET,
              FilterId.CREATIVE,
              FilterId.PUBLISHER,
              FilterId.COUNTRY,
            ],
            undefined,
            true
          ),
        ];
      case ReportType.EVENTS_COST:
        return [
          new DateFilterPreviewModel(
            FilterPreviewId.EVENTS_DATE,
            undefined,
            undefined,
            new DayLimitFilterPreviewModel(FilterPreviewId.EVENTS_DAY_LIMIT)
          ),
          new FilterPreviewModel(
            FilterPreviewId.COUNTRY,
            FilterColor.LIGHT_BLUE
          ),
          new FilterPreviewModel(
            FilterPreviewId.TRACKER,
            FilterColor.LIGHT_GREEN
          ),
          new FilterPreviewModel(FilterPreviewId.EVENT_COST),
          new GroupByFilterPreviewModel(
            [
              FilterId.COUNTRY,
              FilterId.SOURCE,
              FilterId.SUB_SOURCE,
              FilterId.CAMPAIGN,
              FilterId.AD_SET,
              FilterId.PUBLISHER,
              FilterId.CREATIVE,
            ],
            new AggregationPeriodFilterPreviewModel([AggregationPeriod.DAY]),
            true
          ),
        ];
      case ReportType.METRICS_SPEND:
        return [
          new DateFilterPreviewModel(
            undefined,
            undefined,
            undefined,
            new DayLimitFilterPreviewModel(undefined, true)
          ),
          new FilterPreviewModel(
            FilterPreviewId.COUNTRY,
            FilterColor.LIGHT_BLUE
          ),
          new FilterPreviewModel(
            FilterPreviewId.TRACKER,
            FilterColor.LIGHT_GREEN
          ),
          new FilterPreviewModel(FilterPreviewId.ATTRIBUTION_STATUS),
          new FilterPreviewModel(FilterPreviewId.TIME_SPENT_TYPE),
          new GroupByFilterPreviewModel(
            [
              FilterId.COUNTRY,
              FilterId.SOURCE,
              FilterId.CAMPAIGN,
              FilterId.AD_SET,
              FilterId.CREATIVE,
              FilterId.PUBLISHER,
              FilterId.ATTRIBUTION_STATUS,
            ],
            undefined,
            true
          ),
        ];
      case ReportType.METRICS:
        return [
          new DateFilterPreviewModel(
            undefined,
            undefined,
            undefined,
            new DayLimitFilterPreviewModel(undefined, true)
          ),
          new FilterPreviewModel(FilterPreviewId.SEGMENT, FilterColor.RED),
          new FilterPreviewModel(
            FilterPreviewId.COUNTRY,
            FilterColor.LIGHT_BLUE
          ),
          new FilterPreviewModel(
            FilterPreviewId.TRACKER,
            FilterColor.LIGHT_GREEN
          ),
          new FilterPreviewModel(FilterPreviewId.ATTRIBUTION_STATUS),
          new FilterPreviewModel(FilterPreviewId.DEVICE_TYPE),
          new FilterPreviewModel(FilterPreviewId.LAT),
          new FilterPreviewModel(FilterPreviewId.AD_REVENUE_METHOD),
          new FilterPreviewModel(FilterPreviewId.EVENT_SIMPLE),
          new FilterPreviewModel(FilterPreviewId.TIME_SPENT_TYPE),
          new GroupByFilterPreviewModel(
            REPORT_GROUP_BY_FILTER_ID,
            undefined,
            true
          ),
        ];
      case ReportType.SKAD:
        return [
          new DateFilterPreviewModel(
            undefined,
            undefined,
            undefined,
            new DayLimitFilterPreviewModel(undefined, true)
          ),
          new FilterPreviewModel(
            FilterPreviewId.COUNTRY,
            FilterColor.LIGHT_BLUE
          ),
          new FilterPreviewModel(
            FilterPreviewId.TRACKER,
            FilterColor.LIGHT_GREEN
          ),
          new FilterPreviewModel(FilterPreviewId.AD_REVENUE_METHOD),
          new FilterPreviewModel(FilterPreviewId.EVENT_SIMPLE),
          new FilterPreviewModel(FilterPreviewId.CONVERSION_VALUE),
          new FilterPreviewModel(FilterPreviewId.SKAD_REPORT_TYPE),
          new FilterPreviewModel(FilterPreviewId.TIME_SPENT_TYPE),
          new FilterPreviewModel(FilterPreviewId.INCLUDE_IAPS),
          new GroupByFilterPreviewModel(
            [FilterId.COUNTRY, FilterId.SOURCE, FilterId.CAMPAIGN],
            undefined,
            true
          ),
        ];
      case ReportType.WATERFALL:
        return [
          new DateFilterPreviewModel(
            FilterPreviewId.EVENTS_DATE,
            undefined,
            undefined,
            null
          ),
          new FilterPreviewModel(FilterPreviewId.PLATFORMS, FilterColor.PINK),
          new FilterPreviewModel(
            FilterPreviewId.APPLICATIONS,
            FilterColor.PINK
          ),
          new FilterPreviewModel(FilterPreviewId.EVENTS_COUNTRY),
          new FilterPreviewModel(FilterPreviewId.DEVICE_TYPE),
          new FilterPreviewModel(FilterPreviewId.WATERFALLS_ID),
          new FilterPreviewModel(FilterPreviewId.LAT),
          new FilterPreviewModel(FilterPreviewId.AD_NETWORK_NAME),
          new FilterPreviewModel(
            FilterPreviewId.AD_TYPE,
            undefined,
            undefined,
            true
          ),
          new GroupByFilterPreviewModel(
            [
              FilterId.APPLICATION,
              FilterId.EVENTS_COUNTRY,
              FilterId.AD_NETWORK_NAME,
            ],
            new AggregationPeriodFilterPreviewModel(undefined, true),
            true
          ),
        ];
      case ReportType.ARPDAU:
        return [
          new DateFilterPreviewModel(
            FilterPreviewId.EVENTS_DATE,
            undefined,
            undefined,
            null
          ),
          new FilterPreviewModel(FilterPreviewId.SEGMENT, FilterColor.RED),
          new FilterPreviewModel(
            FilterPreviewId.COUNTRY,
            FilterColor.LIGHT_BLUE
          ),
          new FilterPreviewModel(FilterPreviewId.DEVICE_TYPE),
          new FilterPreviewModel(FilterPreviewId.LAT),
          new FilterPreviewModel(FilterPreviewId.AD_NETWORK_NAME),
          new FilterPreviewModel(FilterPreviewId.AD_REVENUE_METHOD),
          new FilterPreviewModel(FilterPreviewId.PROVIDED_BANNER),
          new FilterPreviewModel(FilterPreviewId.USER_ACTIVITY),
          new GroupByFilterPreviewModel(
            [
              FilterId.COUNTRY,
              FilterId.SEGMENT,
              FilterId.DEVICE_TYPE,
              FilterId.AD_NETWORK_NAME,
            ],
            new AggregationPeriodFilterPreviewModel([AggregationPeriod.DAY]),
            true
          ),
        ];
      case ReportType.ARPDAU_SIMPLE:
        return [
          new DateFilterPreviewModel(
            FilterPreviewId.EVENTS_DATE,
            undefined,
            undefined,
            null
          ),
          new FilterPreviewModel(
            FilterPreviewId.COUNTRY,
            FilterColor.LIGHT_BLUE
          ),
          new FilterPreviewModel(FilterPreviewId.DEVICE_TYPE),
          new FilterPreviewModel(FilterPreviewId.AD_NETWORK_NAME),
          new FilterPreviewModel(FilterPreviewId.USER_ACTIVITY),
          new GroupByFilterPreviewModel([
            FilterId.COUNTRY,
            FilterId.AD_NETWORK_NAME,
          ]),
        ];
      case ReportType.CALENDAR_CTR:
        return [
          new DateFilterPreviewModel(
            FilterPreviewId.EVENTS_DATE,
            undefined,
            undefined,
            null
          ),
          new FilterPreviewModel(FilterPreviewId.SEGMENT, FilterColor.RED),
          new FilterPreviewModel(
            FilterPreviewId.TRACKER,
            FilterColor.LIGHT_GREEN
          ),
          new FilterPreviewModel(FilterPreviewId.EVENTS_COUNTRY),
          new FilterPreviewModel(FilterPreviewId.ATTRIBUTION_STATUS),
          new FilterPreviewModel(FilterPreviewId.DEVICE_TYPE),
          new FilterPreviewModel(FilterPreviewId.LAT),
          new FilterPreviewModel(FilterPreviewId.AD_NETWORK_NAME),
          new GroupByFilterPreviewModel(
            [
              FilterId.EVENTS_COUNTRY,
              FilterId.SOURCE,
              FilterId.SUB_SOURCE,
              FilterId.CAMPAIGN,
              FilterId.AD_SET,
              FilterId.CREATIVE,
              FilterId.PUBLISHER,
              FilterId.ATTRIBUTION_STATUS,
              FilterId.DEVICE_TYPE,
              FilterId.SEGMENT,
              FilterId.AD_NETWORK_NAME,
            ],
            new AggregationPeriodFilterPreviewModel([AggregationPeriod.DAY])
          ),
        ];
      case ReportType.CASH_COUNTRY:
        return [
          new DateFilterPreviewModel(
            FilterPreviewId.ATTRIBUTION_DATE_VALUE,
            undefined,
            undefined,
            null
          ),
          new FilterPreviewModel(FilterPreviewId.GOAL, FilterColor.YELLOW),
          new FilterPreviewModel(FilterPreviewId.SEGMENT, FilterColor.RED),
          new FilterPreviewModel(
            FilterPreviewId.COUNTRY,
            FilterColor.LIGHT_BLUE
          ),
          new FilterPreviewModel(
            FilterPreviewId.TRACKER,
            FilterColor.LIGHT_GREEN
          ),
          new FilterPreviewModel(FilterPreviewId.ATTRIBUTION_STATUS),
          new FilterPreviewModel(FilterPreviewId.DEVICE_TYPE),
          new FilterPreviewModel(FilterPreviewId.AD_REVENUE_METHOD),
          new FilterPreviewModel(FilterPreviewId.PROVIDED_BANNER),
          new FilterPreviewModel(FilterPreviewId.EVENT_SIMPLE),
          new GroupByFilterPreviewModel([
            FilterId.COUNTRY,
            FilterId.SOURCE,
            FilterId.SUB_SOURCE,
            FilterId.CAMPAIGN,
            FilterId.AD_SET,
            FilterId.CREATIVE,
            FilterId.PUBLISHER,
            FilterId.ATTRIBUTION_STATUS,
            FilterId.DEVICE_TYPE,
            FilterId.SEGMENT,
          ]),
        ];
      case ReportType.CASH_GAMING:
        return [
          new DateFilterPreviewModel(
            FilterPreviewId.ATTRIBUTION_DATE_VALUE,
            undefined,
            undefined,
            null
          ),
          new FilterPreviewModel(
            FilterPreviewId.TARGET_DAY_MODE,
            FilterColor.YELLOW
          ),
          new FilterPreviewModel(
            FilterPreviewId.COUNTRY,
            FilterColor.LIGHT_BLUE
          ),
          new FilterPreviewModel(
            FilterPreviewId.TRACKER,
            FilterColor.LIGHT_GREEN
          ),
          new GroupByFilterPreviewModel(
            [
              FilterId.COUNTRY,
              FilterId.SOURCE,
              FilterId.CAMPAIGN,
              FilterId.AD_SET,
              FilterId.CREATIVE,
              FilterId.PUBLISHER,
            ],
            new AggregationPeriodFilterPreviewModel([AggregationPeriod.DAY])
          ),
        ];
      case ReportType.COHORT_CPM:
        return [
          new DateFilterPreviewModel(),
          new FilterPreviewModel(FilterPreviewId.SEGMENT, FilterColor.RED),
          new FilterPreviewModel(
            FilterPreviewId.COUNTRY,
            FilterColor.LIGHT_BLUE
          ),
          new FilterPreviewModel(
            FilterPreviewId.TRACKER,
            FilterColor.LIGHT_GREEN
          ),
          new FilterPreviewModel(FilterPreviewId.ATTRIBUTION_STATUS),
          new FilterPreviewModel(FilterPreviewId.DEVICE_TYPE),
          new FilterPreviewModel(FilterPreviewId.LAT),
          new FilterPreviewModel(FilterPreviewId.AD_NETWORK_NAME),
          new FilterPreviewModel(FilterPreviewId.REVENUE),
          new FilterPreviewModel(
            FilterPreviewId.AD_REVENUE_METHOD,
            undefined,
            [],
            true,
            true
          ),
          new FilterPreviewModel(FilterPreviewId.PROVIDED_BANNER),
          new FilterPreviewModel(FilterPreviewId.EVENT_SIMPLE),
          new GroupByFilterPreviewModel([
            ...REPORT_GROUP_BY_FILTER_ID,
            FilterId.AD_NETWORK_NAME,
          ]),
        ];
      case ReportType.COHORT_CTR:
        return [
          new DateFilterPreviewModel(),
          new FilterPreviewModel(FilterPreviewId.SEGMENT, FilterColor.RED),
          new FilterPreviewModel(
            FilterPreviewId.COUNTRY,
            FilterColor.LIGHT_BLUE
          ),
          new FilterPreviewModel(
            FilterPreviewId.TRACKER,
            FilterColor.LIGHT_GREEN
          ),
          new FilterPreviewModel(FilterPreviewId.ATTRIBUTION_STATUS),
          new FilterPreviewModel(FilterPreviewId.DEVICE_TYPE),
          new FilterPreviewModel(FilterPreviewId.AD_NETWORK_NAME),
          new FilterPreviewModel(FilterPreviewId.LAT),
          new FilterPreviewModel(
            FilterPreviewId.AD_TYPE,
            undefined,
            undefined,
            false
          ),
          new GroupByFilterPreviewModel(
            [...REPORT_GROUP_BY_FILTER_ID, FilterId.AD_NETWORK_NAME],
            new AggregationPeriodFilterPreviewModel([AggregationPeriod.DAY])
          ),
        ];
      case ReportType.DIFF_INSTALLS_STORES:
        return [
          new DateFilterPreviewModel(
            FilterPreviewId.EVENTS_DATE,
            undefined,
            undefined,
            null
          ),
          new FilterPreviewModel(
            FilterPreviewId.COUNTRY,
            FilterColor.LIGHT_BLUE
          ),
        ];
      case ReportType.FILL_RATE:
        return [
          new DateFilterPreviewModel(
            FilterPreviewId.ATTRIBUTION_DATE_VALUE,
            undefined,
            undefined,
            null
          ),
          new FilterPreviewModel(FilterPreviewId.EVENTS_COUNTRY),
          new FilterPreviewModel(FilterPreviewId.DEVICE_TYPE),
          new FilterPreviewModel(FilterPreviewId.EVENTS_APP_VERSION),
          new FilterPreviewModel(FilterPreviewId.EVENTS_OS_VERSION),
          new FilterPreviewModel(FilterPreviewId.LAT),
          new GroupByFilterPreviewModel([
            FilterId.DEVICE_TYPE,
            FilterId.EVENTS_COUNTRY,
            FilterId.EVENTS_APP_VERSION,
            FilterId.EVENTS_OS_VERSION,
            FilterId.LAT,
          ]),
          new FilterPreviewModel(FilterPreviewId.EXCLUDE_NO_INTERNET),
        ];
      case ReportType.COHORT_ANALYSIS:
        return [
          new DateFilterPreviewModel(),
          new FilterPreviewModel(FilterPreviewId.SEGMENT, FilterColor.RED),
          new FilterPreviewModel(
            FilterPreviewId.COUNTRY,
            FilterColor.LIGHT_BLUE
          ),
          new FilterPreviewModel(
            FilterPreviewId.TRACKER,
            FilterColor.LIGHT_GREEN
          ),
          new FilterPreviewModel(FilterPreviewId.ATTRIBUTION_STATUS),
          new FilterPreviewModel(FilterPreviewId.INSTALL_APP_VERSION),
          new FilterPreviewModel(FilterPreviewId.REVENUE),
          new FilterPreviewModel(FilterPreviewId.TIME_SPENT_TYPE),
          new FilterPreviewModel(FilterPreviewId.NET_REVENUE),
        ];
      case ReportType.PROFIT:
        return [
          new DateFilterPreviewModel(
            FilterPreviewId.EVENTS_DATE,
            undefined,
            undefined,
            null
          ),
          new FilterPreviewModel(FilterPreviewId.PLATFORMS, FilterColor.PINK),
          new FilterPreviewModel(
            FilterPreviewId.APPLICATIONS,
            FilterColor.PINK
          ),
          new FilterPreviewModel(
            FilterPreviewId.COUNTRY,
            FilterColor.LIGHT_BLUE
          ),
          new GroupByFilterPreviewModel([]),
        ];
      case ReportType.PAYING_USERS_CONVERSION:
        return [
          new DateFilterPreviewModel(
            undefined,
            undefined,
            undefined,
            new DayLimitFilterPreviewModel(undefined, true)
          ),
          new FilterPreviewModel(
            FilterPreviewId.COUNTRY,
            FilterColor.LIGHT_BLUE
          ),
          new FilterPreviewModel(
            FilterPreviewId.TRACKER,
            FilterColor.LIGHT_GREEN
          ),
          new FilterPreviewModel(FilterPreviewId.NET_REVENUE),
        ];
      case ReportType.RETURN_RATE:
        return [
          new DateFilterPreviewModel(),
          new FilterPreviewModel(FilterPreviewId.SEGMENT, FilterColor.RED),
          new FilterPreviewModel(
            FilterPreviewId.COUNTRY,
            FilterColor.LIGHT_BLUE
          ),
          new FilterPreviewModel(
            FilterPreviewId.TRACKER,
            FilterColor.LIGHT_GREEN
          ),
          new FilterPreviewModel(FilterPreviewId.ATTRIBUTION_STATUS),
          new FilterPreviewModel(FilterPreviewId.DEVICE_TYPE),
          new FilterPreviewModel(FilterPreviewId.INSTALL_APP_VERSION),
          new FilterPreviewModel(FilterPreviewId.LAT),
          new GroupByFilterPreviewModel(
            REPORT_GROUP_BY_FILTER_ID,
            undefined,
            true
          ),
        ];
      case ReportType.CALENDAR_PER_MIN:
        return [
          new DateFilterPreviewModel(
            FilterPreviewId.EVENTS_DATE,
            undefined,
            undefined,
            null
          ),
          new FilterPreviewModel(FilterPreviewId.SEGMENT, FilterColor.RED),
          new FilterPreviewModel(
            FilterPreviewId.TRACKER,
            FilterColor.LIGHT_GREEN
          ),
          new FilterPreviewModel(FilterPreviewId.ATTRIBUTION_STATUS),
          new FilterPreviewModel(FilterPreviewId.EVENTS_COUNTRY),
          new FilterPreviewModel(FilterPreviewId.DEVICE_TYPE),
          new FilterPreviewModel(FilterPreviewId.EVENTS_OS_VERSION),
          new FilterPreviewModel(FilterPreviewId.EVENTS_APP_VERSION),
          new FilterPreviewModel(FilterPreviewId.PROVIDED_BANNER),
          new FilterPreviewModel(FilterPreviewId.REPORT_TYPE_PERMIN),
          new FilterPreviewModel(FilterPreviewId.LAT),
          new FilterPreviewModel(FilterPreviewId.ROUND_SESSIONS_TO_SECONDS),
          new GroupByFilterPreviewModel(
            [
              FilterId.EVENTS_COUNTRY,
              FilterId.SOURCE,
              FilterId.SUB_SOURCE,
              FilterId.CAMPAIGN,
              FilterId.AD_SET,
              FilterId.CREATIVE,
              FilterId.PUBLISHER,
              FilterId.ATTRIBUTION_STATUS,
              FilterId.DEVICE_TYPE,
              FilterId.SEGMENT,
            ],
            new AggregationPeriodFilterPreviewModel([AggregationPeriod.DAY])
          ),
        ];
      case ReportType.COHORT_PER_MIN:
        return [
          new DateFilterPreviewModel(),
          new FilterPreviewModel(FilterPreviewId.SEGMENT, FilterColor.RED),
          new FilterPreviewModel(
            FilterPreviewId.COUNTRY,
            FilterColor.LIGHT_BLUE
          ),
          new FilterPreviewModel(
            FilterPreviewId.TRACKER,
            FilterColor.LIGHT_GREEN
          ),
          new FilterPreviewModel(FilterPreviewId.ATTRIBUTION_STATUS),
          new FilterPreviewModel(FilterPreviewId.DEVICE_TYPE),
          new FilterPreviewModel(FilterPreviewId.INSTALL_APP_VERSION),
          new FilterPreviewModel(FilterPreviewId.LAT),
          new FilterPreviewModel(FilterPreviewId.AD_TYPE),
          new FilterPreviewModel(FilterPreviewId.PROVIDED_BANNER),
          new FilterPreviewModel(FilterPreviewId.REPORT_TYPE_PERMIN),
          new FilterPreviewModel(FilterPreviewId.ROUND_SESSIONS_TO_SECONDS),
          new GroupByFilterPreviewModel(
            REPORT_GROUP_BY_FILTER_ID,
            new AggregationPeriodFilterPreviewModel([AggregationPeriod.DAY])
          ),
        ];
      case ReportType.CALENDAR_CPM:
        return [
          new DateFilterPreviewModel(
            FilterPreviewId.EVENTS_DATE,
            undefined,
            undefined,
            null
          ),
          new FilterPreviewModel(FilterPreviewId.PLATFORMS, FilterColor.PINK),
          new FilterPreviewModel(
            FilterPreviewId.APPLICATIONS,
            FilterColor.PINK
          ),
          new FilterPreviewModel(
            FilterPreviewId.COUNTRY,
            FilterColor.LIGHT_BLUE
          ),
          new FilterPreviewModel(FilterPreviewId.AD_NETWORK_NAME),
          new FilterPreviewModel(FilterPreviewId.AD_REVENUE_METHOD),
          new FilterPreviewModel(FilterPreviewId.PROVIDED_BANNER),
          new GroupByFilterPreviewModel(
            [FilterId.APPLICATION, FilterId.COUNTRY, FilterId.AD_NETWORK_NAME],
            new AggregationPeriodFilterPreviewModel([AggregationPeriod.DAY])
          ),
        ];
      case ReportType.SPEND_MINI:
        return [
          new DateFilterPreviewModel(
            FilterPreviewId.EVENTS_DATE,
            undefined,
            undefined,
            null
          ),
          new FilterPreviewModel(FilterPreviewId.PLATFORMS, FilterColor.PINK),
          new FilterPreviewModel(
            FilterPreviewId.APPLICATIONS,
            FilterColor.PINK
          ),
          new FilterPreviewModel(
            FilterPreviewId.COUNTRY,
            FilterColor.LIGHT_BLUE
          ),
          new FilterPreviewModel(
            FilterPreviewId.SOURCE,
            FilterColor.LIGHT_GREEN
          ),
          new GroupByFilterPreviewModel(
            [
              FilterId.PLATFORM,
              FilterId.APPLICATION,
              FilterId.COUNTRY,
              FilterId.SOURCE,
            ],
            undefined,
            true
          ),
        ];
      case ReportType.REVENUE:
        return [
          new DateFilterPreviewModel(
            FilterPreviewId.EVENTS_DATE,
            undefined,
            undefined,
            null
          ),
          new FilterPreviewModel(FilterPreviewId.PLATFORMS, FilterColor.PINK),
          new FilterPreviewModel(
            FilterPreviewId.APPLICATIONS,
            FilterColor.PINK
          ),
          new FilterPreviewModel(FilterPreviewId.EVENTS_COUNTRY),
          new FilterPreviewModel(FilterPreviewId.DEVICE_TYPE),
          new FilterPreviewModel(FilterPreviewId.WATERFALLS_ID),
          new FilterPreviewModel(FilterPreviewId.LAT),
          new FilterPreviewModel(FilterPreviewId.AD_NETWORK_NAME),
          new GroupByFilterPreviewModel(
            [
              FilterId.PLATFORM,
              FilterId.APPLICATION,
              FilterId.EVENTS_COUNTRY,
              FilterId.AD_NETWORK_NAME,
              FilterId.WATERFALLS_ID,
              FilterId.DEVICE_TYPE,
              FilterId.LAT,
            ],
            undefined,
            true
          ),
        ];
      case ReportType.SPEND_HYPER:
        return [
          new DateFilterPreviewModel(
            FilterPreviewId.EVENTS_DATE,
            undefined,
            undefined,
            null
          ),
          new FilterPreviewModel(FilterPreviewId.PLATFORMS, FilterColor.PINK),
          new FilterPreviewModel(
            FilterPreviewId.APPLICATIONS,
            FilterColor.PINK
          ),
          new FilterPreviewModel(
            FilterPreviewId.COUNTRY,
            FilterColor.LIGHT_BLUE
          ),
          new GroupByFilterPreviewModel(
            [FilterId.APPLICATION, FilterId.COUNTRY],
            undefined,
            true
          ),
        ];
      case ReportType.EVENTS_SUMMARY:
        return [
          new DateFilterPreviewModel(
            FilterPreviewId.EVENTS_DATE,
            undefined,
            undefined,
            null
          ),
          new FilterPreviewModel(FilterPreviewId.SEGMENT, FilterColor.RED),
          new FilterPreviewModel(
            FilterPreviewId.COUNTRY,
            FilterColor.LIGHT_BLUE
          ),
          new FilterPreviewModel(
            FilterPreviewId.TRACKER,
            FilterColor.LIGHT_GREEN
          ),
          new FilterPreviewModel(FilterPreviewId.ATTRIBUTION_STATUS),
          new FilterPreviewModel(FilterPreviewId.EVENTS_COUNTRY),
          new FilterPreviewModel(FilterPreviewId.DEVICE_TYPE),
          new FilterPreviewModel(FilterPreviewId.EVENTS_APP_VERSION),
          new FilterPreviewModel(
            FilterPreviewId.GENERATED_EVENT,
            FilterColor.LIME
          ),
          new FilterPreviewModel(FilterPreviewId.EVENT, FilterColor.ORANGE),
          new GroupByFilterPreviewModel(
            [
              FilterId.SEGMENT,
              FilterId.EVENTS_COUNTRY,
              FilterId.SOURCE,
              FilterId.SUB_SOURCE,
              FilterId.CAMPAIGN,
              FilterId.AD_SET,
              FilterId.CREATIVE,
              FilterId.PUBLISHER,
              FilterId.DEVICE_TYPE,
              FilterId.EVENTS_APP_VERSION,
              FilterId.EVENT_PARAMETER,
            ],
            new AggregationPeriodFilterPreviewModel(undefined, true),
            true
          ),
        ];
      case ReportType.MEASURES_LITE:
        return [
          new DateFilterPreviewModel(),
          new FilterPreviewModel(FilterPreviewId.SEGMENT, FilterColor.RED),
          new FilterPreviewModel(
            FilterPreviewId.COUNTRY,
            FilterColor.LIGHT_BLUE
          ),
          new FilterPreviewModel(
            FilterPreviewId.TRACKER,
            FilterColor.LIGHT_GREEN
          ),
          new FilterPreviewModel(FilterPreviewId.ATTRIBUTION_STATUS),
          new FilterPreviewModel(FilterPreviewId.EVENTS_COUNTRY),
          new FilterPreviewModel(FilterPreviewId.DEVICE_TYPE),
          new FilterPreviewModel(FilterPreviewId.INSTALL_APP_VERSION),
          new FilterPreviewModel(FilterPreviewId.LAT),
          new FilterPreviewModel(
            FilterPreviewId.GENERATED_EVENT,
            FilterColor.LIME
          ),
          new FilterPreviewModel(FilterPreviewId.REPORT_TYPE_MEASURES_LITE),
          new FilterPreviewModel(FilterPreviewId.EVENT, FilterColor.ORANGE),
          new GroupByFilterPreviewModel(
            [
              FilterId.COUNTRY,
              FilterId.SOURCE,
              FilterId.SUB_SOURCE,
              FilterId.CAMPAIGN,
              FilterId.AD_SET,
              FilterId.CREATIVE,
              FilterId.PUBLISHER,
              FilterId.ATTRIBUTION_STATUS,
              FilterId.DEVICE_TYPE,
              FilterId.SEGMENT,
              FilterId.LAT,
              FilterId.EVENT_PARAMETER,
            ],
            undefined,
            true
          ),
        ];
      case ReportType.TIME_SPENT:
        return [
          new DateFilterPreviewModel(),
          new FilterPreviewModel(FilterPreviewId.SEGMENT, FilterColor.RED),
          new FilterPreviewModel(
            FilterPreviewId.COUNTRY,
            FilterColor.LIGHT_BLUE
          ),
          new FilterPreviewModel(
            FilterPreviewId.TRACKER,
            FilterColor.LIGHT_GREEN
          ),
          new FilterPreviewModel(FilterPreviewId.ATTRIBUTION_STATUS),
          new FilterPreviewModel(FilterPreviewId.DEVICE_TYPE),
          new FilterPreviewModel(FilterPreviewId.INSTALL_APP_VERSION),
          new FilterPreviewModel(FilterPreviewId.LAT),
          new FilterPreviewModel(FilterPreviewId.REPORT_TYPE_TIME_SPENT),
          new GroupByFilterPreviewModel(
            REPORT_GROUP_BY_FILTER_ID,
            undefined,
            true
          ),
        ];
      case ReportType.SPEND:
        return [
          new DateFilterPreviewModel(
            FilterPreviewId.EVENTS_DATE,
            undefined,
            undefined,
            null
          ),
          new FilterPreviewModel(FilterPreviewId.PLATFORMS, FilterColor.PINK),
          new FilterPreviewModel(
            FilterPreviewId.APPLICATIONS,
            FilterColor.PINK
          ),
          new FilterPreviewModel(
            FilterPreviewId.COUNTRY,
            FilterColor.LIGHT_BLUE
          ),
          new FilterPreviewModel(
            FilterPreviewId.TRACKER,
            FilterColor.LIGHT_GREEN
          ),
          new GroupByFilterPreviewModel(
            [
              FilterId.PLATFORM,
              FilterId.APPLICATION,
              FilterId.COUNTRY,
              FilterId.SOURCE,
              FilterId.CAMPAIGN,
              FilterId.AD_SET,
              FilterId.CREATIVE,
              FilterId.PUBLISHER,
            ],
            undefined,
            true
          ),
        ];
      case ReportType.PRICED_REVENUE:
        return [
          new DateFilterPreviewModel(
            FilterPreviewId.EVENTS_DATE,
            undefined,
            undefined,
            null
          ),
          new FilterPreviewModel(FilterPreviewId.SEGMENT, FilterColor.RED),
          new FilterPreviewModel(
            FilterPreviewId.TRACKER,
            FilterColor.LIGHT_GREEN
          ),
          new FilterPreviewModel(FilterPreviewId.EVENTS_COUNTRY),
          new FilterPreviewModel(FilterPreviewId.DEVICE_TYPE),
          new FilterPreviewModel(FilterPreviewId.LAT),
          new FilterPreviewModel(FilterPreviewId.AD_NETWORK_NAME),
          new FilterPreviewModel(FilterPreviewId.PROVIDED_BANNER),
          new GroupByFilterPreviewModel(
            [
              FilterId.EVENTS_COUNTRY,
              FilterId.SEGMENT,
              FilterId.SOURCE,
              FilterId.SUB_SOURCE,
              FilterId.CAMPAIGN,
              FilterId.AD_SET,
              FilterId.CREATIVE,
              FilterId.PUBLISHER,
              FilterId.DEVICE_TYPE,
              FilterId.LAT,
              FilterId.AD_NETWORK_NAME,
            ],
            undefined,
            true
          ),
          new FilterPreviewModel(FilterPreviewId.NET_REVENUE),
        ];
      case ReportType.MONETIZATION_MONITORING:
        return [
          new DateFilterPreviewModel(
            FilterPreviewId.EVENTS_DATE,
            undefined,
            undefined,
            null
          ),
          new FilterPreviewModel(
            FilterPreviewId.COUNTRY,
            FilterColor.LIGHT_BLUE,
            // 1 means Tier 1
            ["1"]
          ),
          new FilterPreviewModel(FilterPreviewId.AD_NETWORK_NAME),
          new FilterPreviewModel(
            FilterPreviewId.AD_TYPE,
            undefined,
            undefined,
            true
          ),
          new FilterPreviewModel(FilterPreviewId.NO_MONITORING),
        ];
      case ReportType.FIREBASE_SHOW_TO_IMPRESSION:
        return [
          new DateFilterPreviewModel(
            FilterPreviewId.ATTRIBUTION_DATE_VALUE,
            undefined,
            undefined,
            null
          ),
          new FilterPreviewModel(FilterPreviewId.EVENTS_COUNTRY),
          new FilterPreviewModel(FilterPreviewId.AD_NETWORK_NAME),
          new FilterPreviewModel(FilterPreviewId.DEVICE_TYPE),
          new FilterPreviewModel(FilterPreviewId.LAT),
          new GroupByFilterPreviewModel(
            [
              FilterId.AD_NETWORK_NAME,
              FilterId.EVENTS_COUNTRY,
              FilterId.DEVICE_TYPE,
              FilterId.LAT,
            ],
            undefined,
            true
          ),
        ];
      case ReportType.FIREBASE_VS_NETWORKS:
        return [
          new DateFilterPreviewModel(
            FilterPreviewId.EVENTS_DATE,
            undefined,
            undefined,
            null
          ),
          new FilterPreviewModel(FilterPreviewId.EVENTS_COUNTRY),
          new FilterPreviewModel(FilterPreviewId.AD_NETWORK_NAME),
          new FilterPreviewModel(FilterPreviewId.DEVICE_TYPE),
          new FilterPreviewModel(FilterPreviewId.LAT),
          new GroupByFilterPreviewModel(
            [
              FilterId.EVENTS_COUNTRY,
              FilterId.DEVICE_TYPE,
              FilterId.LAT,
              FilterId.AD_NETWORK_NAME,
            ],
            undefined,
            true
          ),
        ];
      case ReportType.FIREBASE_FILL_RATE:
        return [
          new DateFilterPreviewModel(
            FilterPreviewId.EVENTS_DATE,
            undefined,
            undefined,
            null
          ),
          new FilterPreviewModel(FilterPreviewId.EVENTS_COUNTRY),
          new FilterPreviewModel(FilterPreviewId.DEVICE_TYPE),
          new FilterPreviewModel(FilterPreviewId.EVENTS_APP_VERSION),
          new FilterPreviewModel(FilterPreviewId.EVENTS_OS_VERSION),
          new FilterPreviewModel(FilterPreviewId.LAT),
          new GroupByFilterPreviewModel(
            [
              FilterId.DEVICE_TYPE,
              FilterId.EVENTS_COUNTRY,
              FilterId.EVENTS_APP_VERSION,
              FilterId.EVENTS_OS_VERSION,
              FilterId.LAT,
            ],
            new AggregationPeriodFilterPreviewModel([AggregationPeriod.DAY]),
            true
          ),
        ];
      case ReportType.TRAFFIC_QUALITY:
        return [
          new DateFilterPreviewModel(
            undefined,
            undefined,
            undefined,
            new DayLimitFilterPreviewModel(undefined, true)
          ),
          new FilterPreviewModel(FilterPreviewId.SEGMENT, FilterColor.RED),
          new FilterPreviewModel(
            FilterPreviewId.COUNTRY,
            FilterColor.LIGHT_BLUE
          ),
          new FilterPreviewModel(
            FilterPreviewId.TRACKER,
            FilterColor.LIGHT_GREEN
          ),
          new FilterPreviewModel(FilterPreviewId.ATTRIBUTION_STATUS),
          new FilterPreviewModel(FilterPreviewId.DEVICE_TYPE),
          new FilterPreviewModel(FilterPreviewId.LAT),
          new FilterPreviewModel(FilterPreviewId.TIME_SPENT_TYPE),
          new GroupByFilterPreviewModel(
            [
              FilterId.COUNTRY,
              FilterId.SOURCE,
              FilterId.SUB_SOURCE,
              FilterId.CAMPAIGN,
              FilterId.AD_SET,
              FilterId.CREATIVE,
              FilterId.PUBLISHER,
            ],
            undefined,
            true
          ),
        ];
      case ReportType.UA_MAIN_METRICS_OVERVIEW:
        return [
          new DateFilterPreviewModel(
            FilterPreviewId.EVENTS_DATE,
            undefined,
            undefined,
            null
          ),
          new FilterPreviewModel(
            FilterPreviewId.COUNTRY,
            FilterColor.LIGHT_BLUE
          ),
          new FilterPreviewModel(
            FilterPreviewId.TRACKER,
            FilterColor.LIGHT_GREEN
          ),
          new FilterPreviewModel(FilterPreviewId.REVENUE),
          new FilterPreviewModel(FilterPreviewId.AD_REVENUE_METHOD),
          new FilterPreviewModel(FilterPreviewId.PROVIDED_BANNER),
          new FilterPreviewModel(FilterPreviewId.TIME_SPENT_TYPE),
          new FilterPreviewModel(FilterPreviewId.COHORT_METRICS_DAYS),
          new GroupByFilterPreviewModel(
            [
              FilterId.COUNTRY,
              FilterId.SOURCE,
              FilterId.SUB_SOURCE,
              FilterId.CAMPAIGN,
              FilterId.AD_SET,
              FilterId.CREATIVE,
              FilterId.PUBLISHER,
            ],
            new AggregationPeriodFilterPreviewModel(),
            true
          ),
        ];
      case ReportType.SUBSCRIPTIONS_OVERVIEW:
        return [
          new DateFilterPreviewModel(),
          new FilterPreviewModel(FilterPreviewId.SEGMENT, FilterColor.RED),
          new FilterPreviewModel(
            FilterPreviewId.COUNTRY,
            FilterColor.LIGHT_BLUE
          ),
          new FilterPreviewModel(
            FilterPreviewId.TRACKER,
            FilterColor.LIGHT_GREEN
          ),
          new FilterPreviewModel(FilterPreviewId.ATTRIBUTION_STATUS),
          new FilterPreviewModel(FilterPreviewId.INSTALL_APP_VERSION),
          new FilterPreviewModel(FilterPreviewId.NET_REVENUE),
        ];
      case ReportType.METRICS_CONSTRUCTOR:
        return [
          new DateFilterPreviewModel(
            FilterPreviewId.EVENTS_DATE,
            undefined,
            undefined,
            null
          ),
          new FilterPreviewModel(
            FilterPreviewId.COUNTRY,
            FilterColor.LIGHT_BLUE
          ),
          new FilterPreviewModel(
            FilterPreviewId.TRACKER,
            FilterColor.LIGHT_GREEN
          ),
          new GroupByFilterPreviewModel(
            [
              FilterId.COUNTRY,
              FilterId.SOURCE,
              FilterId.SUB_SOURCE,
              FilterId.CAMPAIGN,
              FilterId.AD_SET,
              FilterId.CREATIVE,
              FilterId.PUBLISHER,
            ],
            new AggregationPeriodFilterPreviewModel(),
            true
          ),
        ];
      case ReportType.SUBSCRIPTIONS_OVERVIEW_IOS:
        return [
          new DateFilterPreviewModel(),
          new FilterPreviewModel(FilterPreviewId.SEGMENT, FilterColor.RED),
          new FilterPreviewModel(
            FilterPreviewId.COUNTRY,
            FilterColor.LIGHT_BLUE
          ),
          new FilterPreviewModel(
            FilterPreviewId.TRACKER,
            FilterColor.LIGHT_GREEN
          ),
          new FilterPreviewModel(FilterPreviewId.ATTRIBUTION_STATUS),
          new FilterPreviewModel(FilterPreviewId.INSTALL_APP_VERSION),
          new FilterPreviewModel(FilterPreviewId.NET_REVENUE),
        ];
      case ReportType.EVENTS_SUMMARY_LITE:
        return [
          new DateFilterPreviewModel(
            FilterPreviewId.EVENTS_DATE,
            undefined,
            undefined,
            null
          ),
          new FilterPreviewModel(
            FilterPreviewId.EVENTS_COUNTRY,
            FilterColor.LIGHT_BLUE
          ),
          new FilterPreviewModel(FilterPreviewId.EVENTS_APP_VERSION),
          new FilterPreviewModel(FilterPreviewId.EVENT, FilterColor.ORANGE),
          new GroupByFilterPreviewModel(
            [
              FilterId.EVENTS_COUNTRY,
              FilterId.EVENTS_APP_VERSION,
              FilterId.EVENT_PARAMETER,
            ],
            new AggregationPeriodFilterPreviewModel(undefined, true),
            true
          ),
          new FilterPreviewModel(FilterPreviewId.SHOW_UNIQUE_DEVICES),
        ];
      default:
        return [];
    }
  }

  static segmentItemsFilter(
    reportType: ReportType
  ):
    | ((item: SegmentDictionary, currentValue: Array<string>) => boolean)
    | undefined {
    switch (reportType) {
      case ReportType.AD_ROAS_COUNTRY:
      case ReportType.AD_ROAS_NETWORK:
      case ReportType.ARPDAU:
      case ReportType.ARPDAU_SIMPLE:
      case ReportType.ARPU:
      case ReportType.CALENDAR_CPM:
      case ReportType.CALENDAR_CTR:
      case ReportType.CALENDAR_PER_MIN:
      case ReportType.CASH_COUNTRY:
      case ReportType.CASH_GAMING:
      case ReportType.COHORT_ANALYSIS:
      case ReportType.COHORT_CPM:
      case ReportType.COHORT_CTR:
      case ReportType.COHORT_PER_MIN:
      case ReportType.DIFF_INSTALLS_STORES:
      case ReportType.EVENTS_COST:
      case ReportType.EVENTS_SUMMARY:
      case ReportType.FILL_RATE:
      case ReportType.FIREBASE_FILL_RATE:
      case ReportType.FIREBASE_SHOW_TO_IMPRESSION:
      case ReportType.FIREBASE_VS_NETWORKS:
      case ReportType.MEASURES_LITE:
      case ReportType.METRICS:
      case ReportType.METRICS_SPEND:
      case ReportType.MONETIZATION_MONITORING:
      case ReportType.PAYING_USERS_CONVERSION:
      case ReportType.PRICED_REVENUE:
      case ReportType.PROFIT:
      case ReportType.RETURN_RATE:
      case ReportType.REVENUE:
      case ReportType.SKAD:
      case ReportType.SPEND:
      case ReportType.SPEND_HYPER:
      case ReportType.SPEND_MINI:
      case ReportType.TIME_SPENT:
      case ReportType.TRAFFIC_QUALITY:
      case ReportType.WATERFALL:
        return (item, currentValue) =>
          currentValue.includes(item.value) ||
          (SEGMENT_ACTIVE_STATUSES.includes(
            (item as SegmentDictionary).status
          ) &&
            SEGMENT_TYPES_FOR_REPORTS.includes(
              (item as SegmentDictionary).type
            ));
      default:
        return undefined;
    }
  }

  static trackerItemsFilter(
    reportType: ReportType
  ): ((dictionary: TrackerDictionary) => boolean) | null {
    return [
      ReportType.AD_ROAS_NETWORK,
      ReportType.METRICS_SPEND,
      ReportType.SPEND,
      ReportType.SPEND_MINI,
      ReportType.PAYING_USERS_CONVERSION,
    ].includes(reportType)
      ? ({ value }: TrackerDictionary) => value !== "Undefined"
      : null;
  }

  static getTrackerOrigins(reportType: ReportType): Array<TrackerOrigin> {
    switch (reportType) {
      case ReportType.AD_ROAS_NETWORK:
      case ReportType.METRICS_SPEND:
      case ReportType.SPEND:
      case ReportType.SPEND_MINI:
      case ReportType.PAYING_USERS_CONVERSION:
        return [TrackerOrigin.SPEND, TrackerOrigin.NETWORK_SPEND];
      case ReportType.ARPU:
      case ReportType.CALENDAR_PER_MIN:
      case ReportType.CASH_COUNTRY:
      case ReportType.CASH_GAMING:
      case ReportType.COHORT_ANALYSIS:
      case ReportType.COHORT_CPM:
      case ReportType.COHORT_CTR:
      case ReportType.COHORT_PER_MIN:
      case ReportType.CALENDAR_CTR:
      case ReportType.EVENTS_COST:
      case ReportType.EVENTS_SUMMARY:
      case ReportType.MEASURES_LITE:
      case ReportType.METRICS:
      case ReportType.RETURN_RATE:
      case ReportType.TIME_SPENT:
      case ReportType.PRICED_REVENUE:
      case ReportType.UA_MAIN_METRICS_OVERVIEW:
      case ReportType.METRICS_CONSTRUCTOR:
        return [TrackerOrigin.MARKETING_EVENTS];
      case ReportType.SKAD:
        return [TrackerOrigin.SKAD_EVENTS, TrackerOrigin.NETWORK_SKAD];
      default:
        return [];
    }
  }

  static sourceItemsFilter(
    reportType: ReportType
  ): ((dictionary: TrackerDictionary) => boolean) | null {
    return reportType === ReportType.SPEND_MINI
      ? ({ value }: TrackerDictionary) => value !== "Undefined"
      : null;
  }

  static deviceTypeItemsFilter(
    reportType: ReportType
  ): ((dictionary: Dictionary) => boolean) | null {
    if (
      [
        ReportType.FIREBASE_VS_NETWORKS,
        ReportType.FIREBASE_SHOW_TO_IMPRESSION,
        ReportType.FIREBASE_FILL_RATE,
      ].includes(reportType)
    ) {
      return ({ value }: Dictionary) => ["tablet", "phone"].includes(value);
    }

    return null;
  }

  // TODO: REPORTS make readonly prop for future report model
  static hasHorizontalGradient(reportType: ReportType) {
    return [
      ReportType.AD_ROAS_COUNTRY,
      ReportType.AD_ROAS_NETWORK,
      ReportType.ARPU,
      ReportType.COHORT_CPM,
      ReportType.COHORT_CTR,
      ReportType.COHORT_PER_MIN,
      ReportType.MEASURES_LITE,
      ReportType.RETURN_RATE,
      ReportType.TIME_SPENT,
      ReportType.COHORT_ANALYSIS,
    ].includes(reportType);
  }

  // TODO: REPORTS make readonly prop for future report model
  static isMoneyReport(reportType: ReportType) {
    return [
      ReportType.REVENUE,
      ReportType.PRICED_REVENUE,
      ReportType.ARPDAU,
      ReportType.ARPDAU_SIMPLE,
      ReportType.METRICS,
      ReportType.METRICS_SPEND,
      ReportType.PROFIT,
      ReportType.CASH_COUNTRY,
      ReportType.CASH_GAMING,
      ReportType.ARPU,
      ReportType.EVENTS_COST,
      ReportType.AD_ROAS_COUNTRY,
      ReportType.AD_ROAS_NETWORK,
      ReportType.PAYING_USERS_CONVERSION,
      ReportType.SPEND,
      ReportType.SPEND_HYPER,
      ReportType.SPEND_MINI,
      ReportType.CALENDAR_CPM,
      ReportType.COHORT_CPM,
      ReportType.TRAFFIC_QUALITY,
      ReportType.UA_MAIN_METRICS_OVERVIEW,
      ReportType.SUBSCRIPTIONS_OVERVIEW,
      ReportType.METRICS_CONSTRUCTOR,
      ReportType.SUBSCRIPTIONS_OVERVIEW_IOS,
    ].includes(reportType);
  }
}
