






































































































import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import AccountSettingsModel from "@/account-settings/models/AccountSettingsModel";
import MenuUtil from "@/shared/utils/MenuUtil";
import { MenuModel, CurrentUserModel, MULTI_APP } from "@/shared/models";
import { VueAutocomplete } from "@/shared/types/ExtendedVueType";
import ReportUtil from "@/reports/utils/ReportUtil";
import { AppSection } from "@/shared/models";

@Component
export default class Search extends Vue {
  @Ref("input") input!: VueAutocomplete;

  showSearchOptions = false;
  search = "";

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get applicationId(): string {
    return this.$store.state.application.applicationId;
  }

  get menu(): Array<MenuModel> {
    return this.$store.state.menuStore.menu;
  }

  get hash(): string | undefined {
    return this.$store.state.reportStore.isCommonReportFilterEnabled
      ? this.$store.state.reportStore.tabHash
      : undefined;
  }

  get isSuperAdmin(): boolean {
    return this.$store.getters.isSuperAdmin;
  }

  get isDeveloperOrSuperAdmin(): boolean {
    return this.$store.getters.isDeveloperOrSuperAdmin;
  }

  get currentUser(): CurrentUserModel {
    return this.$store.state.userStore.currentUser;
  }

  get accessEntities(): Array<AppSection> {
    return this.currentUser.viewAccessEntities[
      this.applicationId
    ] as Array<AppSection>;
  }

  get accountSettings(): AccountSettingsModel {
    return this.$store.state.accountSettingsStore.accountSettings;
  }

  get pinnedSearchItems(): Array<string> {
    return this.accountSettings.pinnedSearchItems;
  }

  get searchItems(): Array<Record<string, any>> {
    const healthcheckMenuItems = MenuUtil.getHealthcheckMenuItems(
      this.accessEntities,
      this.applicationId === MULTI_APP
    );

    return [
      {
        id: 2,
        title: this.$lang("shared.features"),
        items: [
          ...this.menu.map(({ icon, label, route, isExact }) => ({
            icon,
            label,
            route: {
              ...route,
              params: { id: this.applicationId },
            },
            isExact,
            isPinned: this.pinnedSearchItems.some(
              (routeName: string) => routeName === route.name
            ),
          })),
          ...(healthcheckMenuItems.length
            ? [
                {
                  icon: "mdi-heart-pulse",
                  label: this.$lang("menu.healthcheck.title"),
                  route:
                    process.env.NODE_ENV === "dev"
                      ? {
                          name: healthcheckMenuItems[0].name,
                          params: { id: this.applicationId },
                        }
                      : {
                          path: this.getV3Path(healthcheckMenuItems[0].name),
                          name: healthcheckMenuItems[0].name,
                        },
                  isExact: false,
                  isPinned: this.pinnedSearchItems.some(
                    (routeName: string) =>
                      routeName === healthcheckMenuItems[0].name
                  ),
                },
              ]
            : []),
        ],
      },
      {
        id: 3,
        title: this.$lang("shared.pages"),
        items: [
          ...(this.currentUser.reportAccessByApp[this.applicationId]?.map(
            (reportType) => ({
              icon: "mdi-poll",
              label: ReportUtil.getReportNameByReportType(reportType),
              parentLabel: this.$lang("menu.main.report"),
              route: {
                name: reportType,
                hash: this.hash,
                params: { id: this.applicationId },
              },
              isPinned: this.pinnedSearchItems.some(
                (routeName: string) => routeName === reportType
              ),
            })
          ) || []),
          ...MenuUtil.getToolsMenuItems(this.accessEntities).map(
            ({ name, icon }) => ({
              icon,
              label: this.$lang(`menu.tools.${name.toLowerCase()}`),
              parentLabel: this.$lang("menu.main.tools"),
              route: {
                name,
                params: { id: this.applicationId },
              },
              isPinned: this.pinnedSearchItems.some(
                (routeName: string) => routeName === name
              ),
            })
          ),
          ...MenuUtil.getTemplateMenuItems(this.accessEntities).map(
            ({ name, icon }) => ({
              icon,
              label: this.$lang(`menu.templates.${name.toLowerCase()}`),
              parentLabel: this.$lang("menu.main.template"),
              route: {
                name,
                params: { id: this.applicationId },
              },
              isPinned: this.pinnedSearchItems.some(
                (routeName: string) => routeName === name
              ),
            })
          ),
          ...MenuUtil.getDashboardMenuItems(this.accessEntities).map(
            ({ name, icon }) => ({
              icon,
              label: this.$lang(`menu.dashboards.${name.toLowerCase()}`),
              parentLabel: this.$lang("menu.main.dashboards"),
              route: {
                name,
                params: { id: this.applicationId },
              },
              isPinned: this.pinnedSearchItems.some(
                (routeName: string) => routeName === name
              ),
            })
          ),
          ...MenuUtil.getSegmentMenuItems(this.accessEntities).map(
            ({ name, icon }) => ({
              icon,
              label: this.$lang(`menu.segments.${name.toLowerCase()}`),
              parentLabel: this.$lang("menu.main.segment"),
              route: {
                name,
                params: { id: this.applicationId },
              },
              isPinned: this.pinnedSearchItems.some(
                (routeName: string) => routeName === name
              ),
            })
          ),
          ...MenuUtil.getAbTestMenuItems(this.accessEntities).map(
            ({ name, icon }) => ({
              icon,
              label: this.$lang(`menu.abTests.${name.toLowerCase()}`),
              parentLabel: this.$lang("menu.main.abTests"),
              route:
                name === AppSection.AB_TESTS_GROUPS_SIZE_RECOMMENDER
                  ? {
                      path: this.getV3Path(name),
                      name,
                    }
                  : {
                      name,
                      params: { id: this.applicationId },
                    },
              isPinned: this.pinnedSearchItems.some(
                (routeName: string) => routeName === name
              ),
            })
          ),
          ...MenuUtil.getSettingMenuItems(this.accessEntities).reduce(
            (
              result: Array<Record<string, any>>,
              settingMenuItem: Record<string, any>
            ) => {
              if (settingMenuItem.items) {
                return [
                  ...result,
                  ...settingMenuItem.items.map((item: Record<string, any>) => ({
                    icon: item.icon,
                    label: this.$lang(
                      `menu.settings.${item.name.toLowerCase()}`
                    ),
                    parentLabel: this.$lang("menu.main.settings"),
                    route:
                      process.env.NODE_ENV === "dev"
                        ? {
                            name: item.name,
                            params: { id: this.applicationId },
                          }
                        : {
                            path: this.getV3Path(item.name),
                            name,
                          },
                    isPinned: this.pinnedSearchItems.some(
                      (routeName: string) => routeName === item.name
                    ),
                  })),
                ];
              }

              return [
                ...result,
                {
                  icon: settingMenuItem.icon,
                  label: this.$lang(
                    `menu.settings.${settingMenuItem.name.toLowerCase()}`
                  ),
                  parentLabel: this.$lang("menu.main.settings"),
                  route:
                    process.env.NODE_ENV === "dev"
                      ? {
                          name: settingMenuItem.name,
                          params: { id: this.applicationId },
                        }
                      : {
                          path: this.getV3Path(settingMenuItem.name),
                          name: settingMenuItem.name,
                        },
                  isPinned: this.pinnedSearchItems.some(
                    (routeName: string) => routeName === settingMenuItem.name
                  ),
                },
              ];
            },
            []
          ),
          ...MenuUtil.getApplicationManagerMenuItems(this.accessEntities).map(
            ({ name, icon }) => ({
              icon,
              label: this.$lang(
                `menu.applicationManager.${name.toLowerCase()}`
              ),
              parentLabel: this.$lang("menu.main.applicationManager"),
              route:
                process.env.NODE_ENV === "dev"
                  ? { name, params: { id: this.applicationId } }
                  : {
                      path: this.getV3Path(name),
                      name,
                    },
              isPinned: this.pinnedSearchItems.some(
                (routeName: string) => routeName === name
              ),
            })
          ),
          ...MenuUtil.getIamMenuItems(this.accessEntities).map(({ name }) => ({
            icon: "mdi-account",
            label: this.$lang(`menu.iam.${name.toLowerCase()}`),
            parentLabel: this.$lang("menu.main.iam"),
            route:
              process.env.NODE_ENV === "dev"
                ? { name }
                : {
                    path: this.getV3Path(name),
                    name,
                  },
            isPinned: this.pinnedSearchItems.some(
              (routeName: string) => routeName === name
            ),
          })),
          ...healthcheckMenuItems.map(({ name, icon }) => ({
            icon,
            label: this.$lang(`menu.healthcheck.${name.toLowerCase()}`),
            parentLabel: this.$lang("menu.healthcheck.title"),
            route:
              process.env.NODE_ENV === "dev"
                ? { name, params: { id: this.applicationId } }
                : {
                    path: this.getV3Path(name),
                    name,
                  },
            isPinned: this.pinnedSearchItems.some(
              (routeName: string) => routeName === name
            ),
          })),
          ...MenuUtil.getAccountingPortalMenuItems(this.accessEntities).map(
            ({ name, icon }) => ({
              icon,
              label: this.$lang(`menu.accountingPortal.${name.toLowerCase()}`),
              parentLabel: this.$lang("menu.main.accountingPortal"),
              route:
                process.env.NODE_ENV === "dev"
                  ? { name }
                  : {
                      path: this.getV3Path(name),
                      name,
                    },
              isPinned: this.pinnedSearchItems.some(
                (routeName: string) => routeName === name
              ),
            })
          ),
        ],
      },
    ];
  }

  get localPinnedSearchItems(): Array<Record<string, any>> {
    return this.searchItems
      .flatMap(({ items }) => items)
      .filter(({ route: { name } }) => this.pinnedSearchItems.includes(name))
      .sort(
        ({ route: { name: nameA } }, { route: { name: nameB } }) =>
          this.pinnedSearchItems.indexOf(nameA) -
          this.pinnedSearchItems.indexOf(nameB)
      );
  }

  get extendedSearchItems(): Array<Record<string, any>> {
    return [
      {
        id: 1,
        title: this.$lang("shared.pinned"),
        items: this.localPinnedSearchItems.map((item) => ({
          ...item,
          route: {
            ...item.route,
            params: { id: this.applicationId },
          },
          isPinned: true,
        })),
      },
      ...this.searchItems,
    ];
  }

  get searchResult(): Array<Record<string, any>> {
    const searchRequest: string = this.search?.toLowerCase() ?? "";

    return this.extendedSearchItems
      .filter((item) =>
        item.items.some((item: any) =>
          item.label.toLowerCase().includes(searchRequest)
        )
      )
      .map((item) => ({
        ...item,
        items: item.items
          .filter((it: any) => it.label.toLowerCase().includes(searchRequest))
          .splice(0, 5),
      }));
  }

  @Watch("$route", { deep: true })
  private watchRoute() {
    this.showSearchOptions = false;
  }

  @Watch("showSearchOptions")
  private watchShowSearchOptions(value: boolean) {
    if (!value) {
      this.input.blur();
    }
  }

  getV3Path(name: AppSection): string {
    return {
      AB_TESTS_GROUPS_SIZE_RECOMMENDER: `/v3/app/${this.applicationId}/ab-test-group-size`,
      AD_REVENUE_EVENTS: `/v3/app/admin/${this.applicationId}/settings/revenue-events`,
      AGGREGATORS: `/v3/app/admin/iam/aggregators`,
      APPLICATIONS: `/v3/app/${this.applicationId}/application-manager/applications`,
      APPLICATION_BASIC_INFO: `/v3/app/${this.applicationId}/application-manager/basic-info`,
      APPLICATION_FEATURES_CONTROL: `/v3/app/${this.applicationId}/application-manager/features-control`,
      DICTIONARIES_APP_VERSIONS: `/v3/app/admin/${this.applicationId}/settings/dictionary/app-versions`,
      DICTIONARIES_OS_VERSIONS: `/v3/app/admin/${this.applicationId}/settings/dictionary/os-versions`,
      HEALTHCHECK_DATA_FLOW: `/v3/healthcheck/dataflow/app/${this.applicationId}`,
      HEALTHCHECK_JOBS_QUEUE: "/v3/healthcheck/jobs-queue",
      HEALTHCHECK_PARSING: `/v3/healthcheck/parsing/app/${this.applicationId}`,
      HEALTHCHECK_SUMMARY: "/v3/healthcheck/summary",
      NETWORKS_MANAGEMENT: `/v3/app/admin/${this.applicationId}/settings/networks-management`,
      PERMISSIONS_BROWSER: `/v3/app/admin/iam/permissions-browser`,
      PLACEMENTS: `/v3/app/admin/${this.applicationId}/settings/placements`,
      SUBSCRIPTION_EVENTS: `/v3/app/admin/${this.applicationId}/settings/subscription-events`,
      TAX_PERCENT: `/v3/app/admin/${this.applicationId}/settings/tax-percents`,
      USERS: `/v3/app/admin/iam/users`,
      BANKS: "/v3/accounting-portal/banks",
      COUNTERPARTIES: "/v3/accounting-portal/counterparties",
      INVOICES: "/v3/accounting-portal/invoices",
    }[
      name as
        | AppSection.AB_TESTS_GROUPS_SIZE_RECOMMENDER
        | AppSection.AD_REVENUE_EVENTS
        | AppSection.AGGREGATORS
        | AppSection.APPLICATIONS
        | AppSection.APPLICATION_BASIC_INFO
        | AppSection.APPLICATION_FEATURES_CONTROL
        | AppSection.DICTIONARIES_APP_VERSIONS
        | AppSection.DICTIONARIES_OS_VERSIONS
        | AppSection.HEALTHCHECK_DATA_FLOW
        | AppSection.HEALTHCHECK_JOBS_QUEUE
        | AppSection.HEALTHCHECK_PARSING
        | AppSection.HEALTHCHECK_SUMMARY
        | AppSection.NETWORKS_MANAGEMENT
        | AppSection.PERMISSIONS_BROWSER
        | AppSection.PLACEMENTS
        | AppSection.SUBSCRIPTION_EVENTS
        | AppSection.TAX_PERCENT
        | AppSection.USERS
        | AppSection.BANKS
        | AppSection.COUNTERPARTIES
        | AppSection.INVOICES
    ];
  }

  getIsDisabledPin(isPinned: boolean): boolean {
    return this.pinnedSearchItems.length === 5 && !isPinned;
  }

  getPinLabel(isPinned: boolean): string {
    if (this.getIsDisabledPin(isPinned)) {
      return this.$lang("shared.maxPinnedItems");
    }

    if (isPinned) {
      return this.$lang("shared.unpin");
    }

    return this.$lang("shared.pin");
  }

  handleClickOutside(event: Event) {
    if (
      (event.target as HTMLElement).id !== "iconPin" &&
      (event.target as HTMLElement).id !== "btnPin"
    ) {
      this.showSearchOptions = false;
    }
  }

  handleClickOnSearchOption() {
    this.search = "";
  }

  handlePinSearchItem(item: Record<string, any>) {
    const accountSettingsCloned = cloneDeep(this.accountSettings);

    if (item.isPinned) {
      accountSettingsCloned.pinnedSearchItems =
        accountSettingsCloned.pinnedSearchItems.filter(
          (routeName: string) => routeName !== item.route.name
        );
    } else {
      accountSettingsCloned.pinnedSearchItems.push(item.route.name);
    }

    this.$store.dispatch("updateAccountSettings", {
      payload: accountSettingsCloned,
    });
  }
}
