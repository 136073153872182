import { intersection } from "lodash";

import { ReportType } from "@/reports/models";
import { AppSection, MenuItems, MenuModel } from "@/shared/models";
import { LangService } from "@/shared/types/LangType";

const getMenuItems = (
  menuItemsMap: Record<string, Record<string, any>>,
  entities: Array<AppSection | ReportType> | undefined
): Array<Record<string, any>> =>
  entities
    ? Object.entries(menuItemsMap).reduce(
        (result: Array<Record<string, any>>, [entity, menuItem]) => {
          if (entities.includes(entity as AppSection)) {
            result.push(menuItem);
          }

          return result;
        },
        []
      )
    : [];

export default class MenuUtil {
  static getAbTestMenuItems(entities: Array<AppSection>) {
    return getMenuItems(
      {
        [AppSection.AB_TESTS]: {
          atid: "ab_test_item",
          name: AppSection.AB_TESTS,
          icon: "social_distance",
        },
        [AppSection.EXTERNAL_TESTS]: {
          atid: "external_test_item",
          name: AppSection.EXTERNAL_TESTS,
          icon: "mdi-import",
        },
        [AppSection.TARGETED_CONFIGS]: {
          atid: "targeted_item",
          name: AppSection.TARGETED_CONFIGS,
          icon: "radar",
        },
        [AppSection.DEFAULT_CONFIG]: {
          atid: "default_item",
          name: AppSection.DEFAULT_CONFIG,
          icon: "list_alt",
        },
        [AppSection.AB_TESTS_GROUPS_SIZE_RECOMMENDER]: {
          name: AppSection.AB_TESTS_GROUPS_SIZE_RECOMMENDER,
          icon: "mdi-account-settings",
        },
      },
      entities
    );
  }

  static getTemplateMenuItems(
    entities: Array<AppSection>
  ): Array<Record<string, any>> {
    return getMenuItems(
      {
        [AppSection.REPORT_TEMPLATES]: {
          name: AppSection.REPORT_TEMPLATES,
          icon: "mdi-chart-bar",
        },
        [AppSection.FUNNEL_TEMPLATES]: {
          name: AppSection.FUNNEL_TEMPLATES,
          icon: "mdi-filter",
        },
      },
      entities
    );
  }

  static getDashboardMenuItems(
    entities: Array<AppSection>
  ): Array<Record<string, any>> {
    return getMenuItems(
      {
        [AppSection.MULTIAPP_DASHBOARDS]: {
          name: AppSection.MULTIAPP_DASHBOARDS,
          icon: "mdi-view-dashboard-variant",
        },
        [AppSection.SINGLEAPP_DASHBOARDS]: {
          name: AppSection.SINGLEAPP_DASHBOARDS,
          icon: "mdi-view-dashboard",
        },
      },
      entities
    );
  }

  static getSegmentMenuItems(
    entities: Array<AppSection>
  ): Array<Record<string, string>> {
    return getMenuItems(
      {
        [AppSection.CUSTOM_DYNAMIC_SEGMENTS]: {
          name: AppSection.CUSTOM_DYNAMIC_SEGMENTS,
          icon: "mdi-account-sync",
        },
        [AppSection.CUSTOM_STATIC_SEGMENTS]: {
          name: AppSection.CUSTOM_STATIC_SEGMENTS,
          icon: "mdi-account-wrench",
        },
        [AppSection.AB_TEST_SEGMENTS]: {
          name: AppSection.AB_TEST_SEGMENTS,
          icon: "mdi-account-switch",
        },
        [AppSection.EXTERNAL_TEST_SEGMENTS]: {
          name: AppSection.EXTERNAL_TEST_SEGMENTS,
          icon: "mdi-account-arrow-up",
        },
        [AppSection.FUNNEL_SEGMENTS]: {
          name: AppSection.FUNNEL_SEGMENTS,
          icon: "mdi-account-filter",
        },
        [AppSection.DIVIDER_SEGMENTS]: {
          name: AppSection.DIVIDER_SEGMENTS,
          icon: "mdi-account-cowboy-hat",
        },
      },
      entities
    );
  }

  static getSettingMenuItems(
    entities: Array<AppSection>
  ): Array<Record<string, any>> {
    const dictionariesMenuItems = getMenuItems(
      {
        [AppSection.DICTIONARIES_APP_VERSIONS]: {
          name: AppSection.DICTIONARIES_APP_VERSIONS,
          icon: "mdi-application-cog",
        },
        [AppSection.DICTIONARIES_OS_VERSIONS]: {
          name: AppSection.DICTIONARIES_OS_VERSIONS,
          icon: "mdi-cellphone-cog",
        },
      },
      entities
    );
    const menuItems = getMenuItems(
      {
        [AppSection.AD_REVENUE_EVENTS]: {
          name: AppSection.AD_REVENUE_EVENTS,
          icon: "mdi-cash-multiple",
        },
        [AppSection.DIVIDER]: {
          name: AppSection.DIVIDER,
          icon: "mdi-account-multiple-outline",
        },
        [AppSection.PLACEMENTS]: {
          name: AppSection.PLACEMENTS,
          icon: "mdi-map-marker-account",
        },
        [AppSection.NETWORKS_MANAGEMENT]: {
          name: AppSection.NETWORKS_MANAGEMENT,
          icon: "mdi-access-point-network",
        },
        [AppSection.SUBSCRIPTION_EVENTS]: {
          name: AppSection.SUBSCRIPTION_EVENTS,
          icon: "mdi-playlist-check",
        },
        [AppSection.TAX_PERCENT]: {
          name: AppSection.TAX_PERCENT,
          icon: "mdi-percent-outline",
        },
      },
      entities
    );

    if (dictionariesMenuItems.length) {
      const indexOfAdRevenueEvents = menuItems.findIndex(
        ({ name }) => name === AppSection.AD_REVENUE_EVENTS
      );

      menuItems.splice(
        indexOfAdRevenueEvents !== -1 ? indexOfAdRevenueEvents + 1 : 0,
        0,
        {
          name: "dictionaries",
          icon: "mdi-application-cog",
          items: dictionariesMenuItems,
        }
      );
    }

    return menuItems;
  }

  static getApplicationManagerMenuItems(
    entities: Array<AppSection>
  ): Array<Record<string, any>> {
    return getMenuItems(
      {
        [AppSection.JURISDICTIONS]: {
          name: AppSection.JURISDICTIONS,
          icon: "mdi-scale-balance",
        },
        [AppSection.SCENARIOS]: {
          name: AppSection.SCENARIOS,
          icon: "mdi-format-list-checks",
        },
        [AppSection.BATCHES]: {
          name: AppSection.BATCHES,
          icon: "mdi-collapse-all-outline",
        },
        [AppSection.APPLICATIONS]: {
          name: AppSection.APPLICATIONS,
          icon: "mdi-application-outline",
        },
        [AppSection.APPLICATION_BASIC_INFO]: {
          name: AppSection.APPLICATION_BASIC_INFO,
          icon: "mdi-application-cog-outline",
        },
        [AppSection.APPLICATION_FEATURES_CONTROL]: {
          name: AppSection.APPLICATION_FEATURES_CONTROL,
          icon: "mdi-application-brackets-outline",
        },
      },
      entities
    );
  }

  static getHealthcheckMenuItems(
    entities: Array<AppSection>,
    isMultiAppMode: boolean
  ): Array<Record<string, any>> {
    return getMenuItems(
      {
        [AppSection.HEALTHCHECK_SUMMARY]: {
          name: AppSection.HEALTHCHECK_SUMMARY,
          icon: "mdi-table-large",
        },
        ...(isMultiAppMode
          ? {}
          : {
              [AppSection.HEALTHCHECK_DATA_FLOW]: {
                name: AppSection.HEALTHCHECK_DATA_FLOW,
                icon: "mdi-database",
              },
              [AppSection.HEALTHCHECK_PARSING]: {
                name: AppSection.HEALTHCHECK_PARSING,
                icon: "mdi-vector-combine",
              },
            }),
        [AppSection.HEALTHCHECK_JOBS_QUEUE]: {
          name: AppSection.HEALTHCHECK_JOBS_QUEUE,
          icon: "mdi-animation-outline",
        },
      },
      entities
    );
  }

  static getToolsMenuItems(
    entities: Array<AppSection | ReportType>
  ): Array<Record<string, any>> {
    return getMenuItems(
      {
        [AppSection.EXPORT_USER_FLOW]: {
          name: AppSection.EXPORT_USER_FLOW,
          icon: "mdi-file-export",
        },
        [ReportType.MONETIZATION_MONITORING]: {
          name: ReportType.MONETIZATION_MONITORING,
          icon: "mdi-cash-100",
        },
        [AppSection.HEALTHCHECK_MONETIZATION_ALERT]: {
          name: AppSection.HEALTHCHECK_MONETIZATION_ALERT,
          icon: "mdi-content-save-cog",
        },
        [AppSection.HEALTHCHECK_MARKETING_ALERT]: {
          name: AppSection.HEALTHCHECK_MARKETING_ALERT,
          icon: "mdi-bell-cog",
        },
      },
      entities
    );
  }

  static getIamMenuItems(entities: Array<AppSection>) {
    return getMenuItems(
      {
        [AppSection.USERS]: {
          name: AppSection.USERS,
          icon: "mdi-account-group",
        },
        [AppSection.AGGREGATORS]: {
          name: AppSection.AGGREGATORS,
          icon: "mdi-account-multiple",
        },
        [AppSection.PERMISSIONS_BROWSER]: {
          name: AppSection.PERMISSIONS_BROWSER,
          icon: "mdi-account-search",
        },
      },
      entities
    );
  }

  static getAccountingPortalMenuItems(entities: Array<AppSection>) {
    return getMenuItems(
      {
        [AppSection.INVOICES]: {
          name: AppSection.INVOICES,
          icon: "mdi-invoice-text",
        },
        [AppSection.BANKS]: {
          name: AppSection.BANKS,
          icon: "mdi-bank",
        },
        [AppSection.COUNTERPARTIES]: {
          name: AppSection.COUNTERPARTIES,
          icon: "mdi-account-group",
        },
      },
      entities
    );
  }

  static generateMainMenu(
    lang: LangService,
    accessByApp: Array<AppSection | ReportType> = [],
    applicationId: string
  ): Array<MenuModel> {
    return (
      [
        [
          [
            MenuItems.REPORT,
            "mdi-poll",
            // `/v3/app/${applicationId}/report`
          ],
          Object.values(ReportType).filter(
            (reportType) => reportType !== ReportType.MONETIZATION_MONITORING
          ),
        ],
        [
          [
            MenuItems.TOOLS,
            "mdi-hammer-wrench",
            // `/v3/app/admin/${applicationId}/tools`,
          ],
          [
            AppSection.EXPORT_USER_FLOW,
            ReportType.MONETIZATION_MONITORING,
            AppSection.HEALTHCHECK_MARKETING_ALERT,
            AppSection.HEALTHCHECK_MONETIZATION_ALERT,
          ],
        ],
        [
          [
            MenuItems.TEMPLATE,
            "mdi-file-document-outline",
            // `/v3/app/${applicationId}/template`
          ],
          [AppSection.REPORT_TEMPLATES, AppSection.FUNNEL_TEMPLATES],
        ],
        [
          [
            MenuItems.DASHBOARDS,
            "mdi-monitor-dashboard",
            // `/v3/app/${applicationId}/dashboards`,
          ],
          [AppSection.MULTIAPP_DASHBOARDS, AppSection.SINGLEAPP_DASHBOARDS],
        ],
        [
          [
            MenuItems.SEGMENT,
            "safety_divider",
            // `/v3/app/${applicationId}/segments`
          ],
          [
            AppSection.AB_TEST_SEGMENTS,
            AppSection.FUNNEL_SEGMENTS,
            AppSection.DIVIDER_SEGMENTS,
            AppSection.CUSTOM_STATIC_SEGMENTS,
            AppSection.EXTERNAL_TEST_SEGMENTS,
            AppSection.CUSTOM_DYNAMIC_SEGMENTS,
          ],
        ],
        [
          [
            MenuItems.FUNNEL,
            "filter_alt",
            // `/v3/app/${applicationId}/funnels`
          ],
          [AppSection.FUNNELS],
        ],
        [
          [
            MenuItems.AB_TESTS,
            "social_distance",
            // `/v3/app/${applicationId}/ab-tests`,
          ],
          [
            AppSection.AB_TESTS,
            AppSection.EXTERNAL_TESTS,
            AppSection.DEFAULT_CONFIG,
            AppSection.TARGETED_CONFIGS,
            AppSection.AB_TESTS_GROUPS_SIZE_RECOMMENDER,
          ],
        ],
        [
          [
            MenuItems.SETTINGS,
            "mdi-cog",
            process.env.NODE_ENV === "dev"
              ? ""
              : `/v3/app/admin/${applicationId}/settings`,
          ],
          [
            AppSection.AD_REVENUE_EVENTS,
            AppSection.DICTIONARIES_APP_VERSIONS,
            AppSection.DICTIONARIES_OS_VERSIONS,
            AppSection.DIVIDER,
            AppSection.PLACEMENTS,
            AppSection.NETWORKS_MANAGEMENT,
            AppSection.SUBSCRIPTION_EVENTS,
          ],
        ],
        [
          [
            MenuItems.APPLICATION_MANAGER,
            "mdi-cellphone-cog",
            process.env.NODE_ENV === "dev"
              ? ""
              : `/v3/app/${applicationId}/application-manager`,
          ],
          [
            AppSection.JURISDICTIONS,
            AppSection.SCENARIOS,
            AppSection.BATCHES,
            AppSection.APPLICATIONS,
            AppSection.APPLICATION_BASIC_INFO,
            AppSection.APPLICATION_FEATURES_CONTROL,
          ],
        ],
        [
          [
            MenuItems.USER_ALERTS,
            "mdi-alert",
            process.env.NODE_ENV === "dev" ? "" : "/v3/user-alerts",
          ],
          [AppSection.ALERT_SYSTEM],
        ],
        [
          [
            MenuItems.IAM,
            "mdi-account",
            process.env.NODE_ENV === "dev" ? "" : "/v3/app/admin/iam",
          ],
          [
            AppSection.USERS,
            AppSection.AGGREGATORS,
            AppSection.PERMISSIONS_BROWSER,
          ],
        ],
        [
          [
            MenuItems.ACCOUNTING_PORTAL,
            "mdi-account-cash",
            process.env.NODE_ENV === "dev" ? "" : "/v3/accounting-portal",
          ],
          [AppSection.INVOICES, AppSection.BANKS, AppSection.COUNTERPARTIES],
        ],
      ] as [
        [MenuItems, string, string, boolean?],
        (AppSection | ReportType)[]
      ][]
    ).reduce(
      (
        result: Array<MenuModel>,
        [[name, icon, routeToV3, isExact], sections]
      ) => {
        if (intersection(accessByApp, sections).length) {
          result.push(
            new MenuModel(
              name,
              lang(`menu.main.${name}`),
              icon,
              { name, path: routeToV3 || undefined },
              isExact
            )
          );
        }

        return result;
      },
      [
        new MenuModel(
          MenuItems.HOME,
          lang(`menu.main.${MenuItems.HOME}`),
          "mdi-home",
          { name: MenuItems.HOME },
          true
        ),
      ]
    );
  }
}
